import {Helmet} from "react-helmet";
import React, {useCallback, useEffect} from "react";
import {Autocomplete, Grid, Typography, useMediaQuery} from "@mui/material";
import {t} from "i18next";
import {useTheme} from "@mui/system";
import {getCustomValue} from "../../../utils/helper.utils";
import {useAppSelector} from "../../store";
import {useCheckAvailabilityWorkflow} from "./hooks/useCheckAvailabilityWorkflow";
import {Field, Form, Formik, FormikProps} from "formik";
import {TextField} from "formik-mui";
import {LoadingButton} from "@mui/lab";
import {Trans} from "react-i18next";
import {useDispatch} from "react-redux";
import * as yup from "yup";
import dayjs, {Dayjs} from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";
import {getFormattedPrice} from "../../../utils/formatCurrency.utils";
import {setPickup} from "../../features/parcel/parcel-slice";

export interface PickupFragmentProps {
  handleNext: any;
  pageTitle: any;
}

type PickupFormValues = {
  pickupDate?: Dayjs | null;
  latestTime: string | null | undefined;
  readyTime: string | null | undefined;
};

export default function PickupFragment({
  handleNext,
  pageTitle,
}: PickupFragmentProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const i18nKeyPrefix = "delivery.pickup.";

  const orderData = useAppSelector((state: any) => state.parcelState);
  const pickupCheckAvailabilityWorkflowId = getCustomValue(
    orderData?.order?.deliveryToTrtMethod?.carrier?.customValues,
    "pickup_check_availability_workflow_id",
  );
  const shippingFromContactAddressId =
    orderData?.order?.contactAddressValues?.contactAddressId;

  const {checkAvailability, isLoading} = useCheckAvailabilityWorkflow(
    pickupCheckAvailabilityWorkflowId,
  );

  const [availableOptions, setAvailableOptions] = React.useState<any>(null);

  const initialValues = orderData?.pickup;
  const pickupFormInitialValues: PickupFormValues = {
    pickupDate: initialValues?.pickupDate
      ? dayjs(initialValues?.pickupDate)
      : dayjs(),
    readyTime: initialValues?.readyTime ?? null,
    latestTime: initialValues?.latestTime ?? null,
  };

  const handleCheckAvailability = useCallback(
    (values: PickupFormValues) => {
      if (checkAvailability && shippingFromContactAddressId) {
        checkAvailability({
          contactAddressId: shippingFromContactAddressId,
          dispatchDate: values?.pickupDate
            ? values?.pickupDate?.format("YYYY-MM-DD")
            : dayjs().format("YYYY-MM-DD"),
        })?.then((options) => {
          setAvailableOptions(options ?? []);
        });
      }
      setAvailableOptions([]);
    },
    [checkAvailability, shippingFromContactAddressId],
  );

  useEffect(() => {
    if (handleCheckAvailability) {
      handleCheckAvailability(pickupFormInitialValues);
    }
  }, [handleCheckAvailability]);

  const getTimeLabel = useCallback(
    (time: any) => {
      if (!time) return "";
      const timeFormatted = dayjs(time, "HH:mm:ss").format("H:mm A");
      if (timeFormatted === "Invalid date") return "";
      return timeFormatted;
    },
    [dayjs],
  );

  const pickupAmount = getFormattedPrice(
    orderData?.order?.deliveryMethod?.pickupAmount,
  );

  const renderForm = useCallback(
    (formikProps: FormikProps<PickupFormValues>) => {
      const selectedOption = availableOptions?.find(
        (x: any) =>
          x.pickupDate ===
            formikProps?.values?.pickupDate?.format("YYYY-MM-DD") &&
          x.available,
      );

      const readyTimeOptions = selectedOption?.readyTimeOptions ?? [];
      const latestTimeOptions = selectedOption?.latestTimeOptions ?? [];
      const defaultReadyTime =
        selectedOption?.defaultReadyTime ?? readyTimeOptions[0] ?? null;
      const defaultLatestTime =
        selectedOption?.defaultLatestTimeOptions ??
        latestTimeOptions[0] ??
        null;

      let formikChangedValues = formikProps?.values;

      if (
        selectedOption &&
        formikProps?.values?.readyTime &&
        !readyTimeOptions.includes(formikProps?.values?.readyTime)
      ) {
        formikChangedValues = {...formikChangedValues, readyTime: null};
      } else if (!formikProps?.values?.readyTime && defaultReadyTime) {
        formikChangedValues = {
          ...formikChangedValues,
          readyTime: defaultReadyTime,
        };
      }

      if (
        selectedOption &&
        formikProps?.values?.latestTime &&
        !latestTimeOptions.includes(formikProps?.values?.latestTime)
      ) {
        formikChangedValues = {...formikChangedValues, latestTime: null};
      } else if (!formikProps?.values?.latestTime && defaultLatestTime) {
        formikChangedValues = {
          ...formikChangedValues,
          latestTime: defaultLatestTime,
        };
      }

      if (formikChangedValues !== formikProps?.values) {
        formikProps?.setValues(formikChangedValues);
      }

      return (
        <Form>
          <Grid container gap={3} mb={3}>
            {pickupAmount && (
              <Grid container>
                <Typography variant={"h3"}>
                  <Trans i18nKey={i18nKeyPrefix + "cost"}>
                    Pickup cost: {{pickupAmount}}
                  </Trans>
                </Typography>
              </Grid>
            )}
            <Grid container gap={2}>
              <Grid xs={12} md={3} lg={2}>
                <DatePicker
                  value={formikProps?.values?.pickupDate}
                  onChange={(value) => {
                    formikProps?.setFieldValue("pickupDate", value);
                    handleCheckAvailability({
                      ...formikProps?.values,
                      pickupDate: value,
                    });
                  }}
                  renderInput={(params: any) => (
                    <Field
                      {...params}
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      label={t(i18nKeyPrefix + "pickupDateLabel")}
                      data-testid="input-pickup-date"
                      name="pickupDate"
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} md={3} lg={2}>
                <Autocomplete
                  options={readyTimeOptions}
                  disabled={isLoading}
                  value={formikProps?.values?.readyTime}
                  getOptionLabel={(option: any) => getTimeLabel(option)}
                  size={isDesktop ? "medium" : "small"}
                  renderInput={(params: any) => (
                    <Field
                      {...params}
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      label={t(i18nKeyPrefix + "readyTimeLabel")}
                      data-testid="input-ready-time"
                      name="readyTime"
                    />
                  )}
                  onChange={(_: any, value: any | null) => {
                    formikProps?.setFieldValue("readyTime", value);
                  }}
                />
              </Grid>
              <Grid xs={12} md={3} lg={2}>
                <Autocomplete
                  options={latestTimeOptions}
                  disabled={isLoading}
                  value={formikProps?.values?.latestTime}
                  getOptionLabel={(option: any) => getTimeLabel(option)}
                  size={isDesktop ? "medium" : "small"}
                  renderInput={(params: any) => (
                    <Field
                      {...params}
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      label={t(i18nKeyPrefix + "latestTimeLabel")}
                      data-testid="input-latest-time"
                      name="latestTime"
                    />
                  )}
                  onChange={(_: any, value: any | null) => {
                    formikProps?.setFieldValue("latestTime", value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <LoadingButton
                type={"submit"}
                variant="contained"
                color="secondary"
                loading={formikProps?.isSubmitting}
                disabled={isLoading || !formikProps?.isValid}
                sx={{minWidth: "200px"}}
              >
                <Trans i18nKey={"delivery.pickup.continue"}>Continue</Trans>
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      );
    },
    [
      isLoading,
      availableOptions,
      getTimeLabel,
      pickupAmount,
      getFormattedPrice,
    ],
  );

  const handleSubmit = (values: PickupFormValues) => {
    dispatch(
      setPickup({
        values: {
          ...values,
          pickupDate: values?.pickupDate?.format("YYYY-MM-DD"),
        },
      }),
    );
    if (handleNext) handleNext();
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {!isDesktop ? (
        <Typography variant={"h2"} textTransform={"capitalize"} mt={3} mb={2}>
          {t(i18nKeyPrefix + "pageTitle")}
        </Typography>
      ) : null}
      <Grid>
        <Formik
          initialValues={pickupFormInitialValues}
          validationSchema={() =>
            yup.object().shape({
              pickupDate: yup.object().required(),
              readyTime: yup.string().required().nullable(),
              latestTime: yup.string().required().nullable(),
            })
          }
          onSubmit={handleSubmit}
        >
          {(formikProps) => renderForm(formikProps)}
        </Formik>
      </Grid>
    </>
  );
}
