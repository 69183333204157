import React from "react";
import {Divider} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import LinearCheckProgressStep from "../../common/components/linearCheckProgressStep";
import RadialProgressStep from "../../common/components/radialProgressStep";
import {ParcelDeliveryStep} from "../pages/ParcelDelivery";

type ParcelStatusBarProps = {
  variant: string;
  currentStep: number;
  setStep: any;
  steps: ParcelDeliveryStep[];
};

export default function ParcelStatusBar({
  variant,
  currentStep,
  setStep,
  steps,
}: ParcelStatusBarProps) {
  const preparedSteps = (steps || []).map((step) => {
    return {
      key: step?.key,
      name: step?.statusBarTitle?.toLowerCase().replace(/ /g, "-"),
      checked: false,
      text: step?.statusBarTitle,
    };
  });

  const sizeList = new Array(preparedSteps.length).fill(1);

  return variant == "linear" ? (
    <Grid>
      <Grid
        container
        columns={14}
        justifyContent="center"
        sx={{position: "relative", zIndex: "2", mt: 2}}
      >
        <Grid xs={12}>
          <LinearCheckProgressStep
            steps={preparedSteps}
            sizeList={sizeList}
            stepIndex={currentStep}
            setStep={setStep}
            orderStep={currentStep}
          />
        </Grid>
      </Grid>
      <Divider
        sx={{
          position: "relative",
          bottom: "2px",
          backgroundColor: "#D8E6FB",
          zIndex: "1",
        }}
      />
    </Grid>
  ) : variant == "radial" ? (
    <RadialProgressStep steps={preparedSteps} stepIndex={currentStep} />
  ) : (
    <></>
  );
}
