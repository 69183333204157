import {Button, Typography, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/system";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import ContentLayout from "../../common/ContentLayout/components/contentLayout";
import MainLayout from "../../common/MainLayout/components/mainLayout";
import {
  resetOrder,
  setStepState,
  setWorkflowResult,
} from "../../features/parcel/parcel-slice";
import {useAppSelector} from "../../store";
import ParcelStatusBar from "../components/parcelStatusBar";
import {DeclarationFragment} from "../Declaration/components/declarationFragment";
import {DeliveryConfiguration} from "../delivery.module";
import ParcelDeliveryAddressFragment from "../DeliveryAddress/components/parcelDeliveryAddressFragment";
import ParcelDeliveryMethodFragment from "../DeliveryMethod/components/parcelDeliveryMethodFragment";
import {FinishAndPayFragment} from "../FinishAndPay";
import {InsuranceFragment} from "../Insurance";
import ShippingAddressFragment from "../ShippingAddress/components/ShippingAddressFragment";
import PayAndPrintLabel from "../PayAndPrintLabel";
import {getCustomValue} from "../../../utils/helper.utils";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../features/workflowTriggers/workflowExecution-api";
import {CreateChargeCommand} from "../../features/order/order-api";
import {toastError} from "../../common/utils/toastMessages";
import ModalForm from "../../common/Modal/components/modalForm";
import Grid from "@mui/material/Unstable_Grid2";
import Link from "../../common/components/link";
import {checkObjectChangeByPath} from "../../common/utils/checkObjectChangeByPath";
import DropOffLocationsFragment from "../Locations/dropOffLocationsFragment";
import {DeliveryState} from "./index";
import PickupFragment from "../Pickup/pickupFragment";

type ParcelDeliveryProps = {
  config: DeliveryConfiguration;
};

export type ParcelDeliveryStep = {
  key: string;
  title: string;
  statusBarTitle: string;
  component: React.ReactNode;
  isDisabled?: boolean;
  dependencies?: string[];
};

type ParcelDeliveryState = {
  orderData: any;
};

const fallbackLink = "../parcelShipments";

export const ParcelDelivery = ({config}: ParcelDeliveryProps) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const {step: stepKey} = useParams();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const orderData = useAppSelector((state: any) => state.parcelState);
  const workflowId = useAppSelector((state: any) => {
    return state.organizationConfigState?.workflows?.payAndPrintLabelWorkflowId;
  });
  const showDropOffStep =
    useAppSelector((state: any) => {
      return state.organizationConfigState?.modules?.delivery?.showDropOffStep;
    }) === true;
  const isPickupEnabled =
    useAppSelector((state: any) => {
      return state.organizationConfigState?.modules?.delivery?.enablePickup;
    }) === true;
  const hasPickupCheckAvailability = !!getCustomValue(
    orderData?.order?.deliveryToTrtMethod?.carrier?.customValues,
    "pickup_create_request_workflow_id",
  );
  const isPickupSelected = orderData?.order?.deliveryMethod?.isPickup;
  const currentCustomerId = useAppSelector(
    (state) => state.userState.contactId,
  );

  const {order: currentOrder, pickup, completedSteps} = orderData;

  const isPaymentRequired =
    getCustomValue(
      orderData?.order?.deliveryToTrtMethod?.carrier?.customValues,
      "is_payment_required",
    ) === "true";

  const [runWorkflow] = useWorkflowExecutionMutation();

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleSubmitRequest = async () => {
    setIsSubmitting(true);
    const totalCost = currentOrder?.chargesTotalPrice;
    if (!currentOrder || !totalCost) return;

    const container = {
      ...currentOrder.container,
      description: "container",
      quantity: 1,
      containerCommodities: currentOrder.commodities,
      customValues: {
        ...currentOrder.container?.customValues,
        customer_declared_weight: currentOrder.container?.weight,
        customer_declared_weight_unit: currentOrder.container?.weightUnit,
      },
    };

    const additionalServicesCharges = currentOrder.charges.filter(
      (x: CreateChargeCommand) =>
        x.values?.rateId == null &&
        x.values?.accountingItemCode !== config?.discountAccountingItemCode,
    );

    let data: any = {
      customerId: currentCustomerId,
      originContactId: currentOrder.contactValues.contactId,
      originAddressId: currentOrder.contactAddressValues.contactAddressId,
      consigneeContactId: currentOrder.consigneeValues.contactId,
      consigneeContactAddressId:
        currentOrder.consigneeAddressValues.contactAddressId,
      firstMileRateId: currentOrder.deliveryToTrtMethod.rateId,
      finalMileRateId: currentOrder.deliveryMethod.rateId,
      commodities: [container],
      charges: additionalServicesCharges,
      amount: totalCost.toFixed(2),
      selected_agent_contact_id:
        currentOrder.customValues?.selected_agent_contact_id,
      selected_agent_address_id:
        currentOrder.customValues?.selected_agent_address_id,
      pickupInfo: currentOrder.deliveryMethod.isPickup
        ? {
            ...pickup,
            pickup_accounting_item_code: getCustomValue(
              currentOrder?.deliveryToTrtMethod?.carrier?.customValues,
              "pickup_accounting_item_code",
            ),
            pickup_amount: currentOrder?.deliveryMethod?.pickupAmount,
          }
        : null,
    };

    if (isPaymentRequired) {
      data = {
        ...data,
        card: {
          number: orderData.paymentCardValues.cardNumber,
          expire: orderData.paymentCardValues.expDate,
          cvv: orderData.paymentCardValues.code,
          cardHolder: orderData.paymentCardValues.holderName,
        },
        billingAddress: orderData.billingAddressValues,
      };
    }

    const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      workflowId: workflowId,
      values: {variables: {...data}},
    };

    runWorkflow(executeWorkflowApiArgs)
      .then((response: any) => {
        if (response.error) {
          throw response;
        }
        const workflowResult = response.data;
        if (
          workflowResult?.outputs?.orderId &&
          (!isPaymentRequired || workflowResult?.outputs?.captureTransactionId)
        ) {
          dispatch(setWorkflowResult({workflowResult}));
        } else {
          toastError(t("toasts.serverError"));
        }
        return;
      })
      .catch((error) => {
        toastError(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleNext = () => {
    if (completedSteps && stepKey) {
      dispatch(
        setStepState({
          step: stepKey,
          isCompleted: true,
        }),
      );
      const newCompletedSteps = {
        ...completedSteps,
        [stepKey]: true,
      };
      const firstNotCompletedStep = getFirstNotCompletedStep(newCompletedSteps);
      navigateToStep(firstNotCompletedStep);
    }
    window.scrollTo(0, 0);
  };

  const handlePrev = () => {
    const previousStepIndex = currentStepIndex - 1;
    if (previousStepIndex >= 0) {
      navigateToStep(steps?.[previousStepIndex]?.key);
    } else {
      navigate(fallbackLink);
    }
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    dispatch(resetOrder());
    navigateToStep(steps?.[0]?.key);

    return () => {
      dispatch(resetOrder());
    };
  }, []);

  const steps: ParcelDeliveryStep[] = [
    {
      key: "shippingAddress",
      title: "delivery.address.fromAddressTitle",
      statusBarTitle: t("delivery.statusBar.yourAddress"),
      component: (
        <ShippingAddressFragment
          key={"shippingAddress"}
          handleNext={handleNext}
          pageTitle={"Ship Request - Shipping Address"}
          config={config}
          defaultCountry={config?.defaultOriginCountry}
        />
      ),
    },
    {
      key: "address",
      title: "delivery.statusBar.deliveryAddress",
      statusBarTitle: t("delivery.statusBar.deliveryAddress"),
      component: (
        <ParcelDeliveryAddressFragment
          key={"address"}
          handleNext={handleNext}
          pageTitle={"Ship Request - Delivery Address"}
          config={config}
        />
      ),
    },
    {
      key: "declaration",
      title: "delivery.declaration.fillOutCustomsDeclarations",
      statusBarTitle: t("delivery.statusBar.customsDeclaration"),
      component: (
        <DeclarationFragment
          key={"declaration"}
          handleNext={handleNext}
          pageTitle={"Ship Request - Customs Declaration"}
          config={config}
        />
      ),
      dependencies: ["orderData.order.consigneeAddressValues.countryCode"],
    },
    {
      key: "method",
      title: "delivery.declaration.parcelDeliveryMethod",
      statusBarTitle: t("delivery.statusBar.deliveryMethod"),
      component: (
        <ParcelDeliveryMethodFragment
          key={"method"}
          handleNext={handleNext}
          pageTitle={"Ship Request - Delivery Methods"}
          config={config}
        />
      ),
      dependencies: [
        "orderData.order.consigneeAddressValues",
        "orderData.order.container",
        "orderData.order.commodities",
      ],
    },
    {
      key: "pickup",
      title: "delivery.pickup.pageTitle",
      statusBarTitle: t("delivery.pickup.statusBar"),
      component: (
        <PickupFragment
          key={"pickup"}
          handleNext={handleNext}
          pageTitle={"Ship Request - Pickup"}
        />
      ),
      dependencies: [
        "orderData.order.contactAddressValues",
        "orderData.order.deliveryToTrtMethod",
      ],
      isDisabled:
        !isPickupEnabled || !hasPickupCheckAvailability || !isPickupSelected,
    },
    {
      key: "locations",
      title: "delivery.dropOffLocations.pageTitle",
      statusBarTitle: t("delivery.statusBar.dropOffLocations"),
      component: (
        <DropOffLocationsFragment
          key={"locations"}
          handleNext={handleNext}
          pageTitle={"Ship Request - Drop Off Locations"}
        />
      ),
      dependencies: [
        "orderData.order.contactAddressValues",
        "orderData.order.deliveryToTrtMethod",
      ],
      isDisabled: !showDropOffStep,
    },
    {
      key: "insurance",
      title: "delivery.statusBar.insurance",
      statusBarTitle: t("delivery.statusBar.insurance"),
      component: (
        <InsuranceFragment
          key={"insurance"}
          handleNext={handleNext}
          pageTitle={"Ship Request - Additional Services"}
          config={config}
        />
      ),
      dependencies: [
        "orderData.order.deliveryMethod",
        "orderData.order.deliveryToTrtMethod",
        "orderData.order.container",
        "orderData.order.commodities",
      ],
    },
    {
      key: "confirmation",
      title: "delivery.finishAndPay.title",
      statusBarTitle: t("delivery.statusBar.confirmation"),
      component: (
        <FinishAndPayFragment
          key={"confirmation"}
          handleNext={async () => {
            if (isPaymentRequired) {
              return handleNext();
            }
            return handleSubmitRequest();
          }}
          isSubmitting={isSubmitting}
          config={config}
        />
      ),
      dependencies: ["orderData.order"],
    },
    {
      key: "pay",
      title: "delivery.payAndPrintLabel.title",
      statusBarTitle: t("delivery.statusBar.payment"),
      isDisabled: !isPaymentRequired,
      component: (
        <PayAndPrintLabel
          key={"payment"}
          pageTitle={"Ship Request - Pay & Print Label"}
          config={config}
          isSubmitting={isSubmitting}
          handleNext={async () => {
            return handleSubmitRequest();
          }}
        />
      ),
      dependencies: ["orderData.order"],
    },
  ].filter((step) => {
    return !step.isDisabled;
  });

  const getFirstNotCompletedStep = (completedSteps: {
    [step: string]: boolean;
  }) => {
    const stepKeys = steps.map((x) => x.key);
    for (const stepKey of stepKeys) {
      if (!completedSteps[stepKey]) {
        return stepKey;
      }
    }
    return stepKeys?.[0] ?? "";
  };

  const currentStepIndex = useMemo(() => {
    let index = steps.findIndex((step) => step.key === stepKey);
    index = index < 0 ? 0 : index;
    return index;
  }, [steps, stepKey]);

  const navigateToStep = (stepKey: string) => {
    navigate(getLink(stepKey));
  };

  const getLink = (stepKey: string) => {
    if (!stepKey) {
      return fallbackLink;
    }
    return `../delivery/new/${stepKey}`;
  };

  const [previousState, setPreviousState] = useState<ParcelDeliveryState>();

  const checkStepsResetConditions = useCallback(
    (previousState: DeliveryState, currentState: DeliveryState) => {
      if (completedSteps) {
        Object.keys(completedSteps).forEach((stepKey) => {
          const stepIndex = steps?.findIndex((x) => x.key === stepKey);
          if (stepIndex < 0) return;
          const step = steps[stepIndex];
          if (
            stepKey &&
            completedSteps[stepKey] === true &&
            stepIndex > currentStepIndex &&
            step?.dependencies
          ) {
            const stepDependencies = step.dependencies;
            stepDependencies?.forEach((stepDependencyPath) => {
              const isStepDependencyChanged = checkObjectChangeByPath(
                previousState,
                currentState,
                stepDependencyPath,
              );
              if (isStepDependencyChanged) {
                dispatch(
                  setStepState({
                    step: stepKey,
                    isCompleted: false,
                  }),
                );
                return;
              }
            });
          }
        });
      }
    },
    [steps, completedSteps, currentStepIndex],
  );

  useEffect(() => {
    const currentState: ParcelDeliveryState = {orderData};
    if (previousState) checkStepsResetConditions(previousState, currentState);
    setPreviousState(currentState);
  }, [orderData]);

  return (
    <>
      <MainLayout
        handlePrevStep={handlePrev}
        statusBar={
          <ParcelStatusBar
            variant={isDesktop ? "linear" : "radial"}
            currentStep={currentStepIndex}
            setStep={(stepKey: string) => {
              navigateToStep(stepKey);
            }}
            steps={steps}
          />
        }
        pageTitle="Ship Request"
      >
        <ContentLayout
          content={steps[currentStepIndex].component}
          title={
            (
              <Trans i18nKey={steps[currentStepIndex].title} />
            ) as React.SetStateAction<any>
          }
          withoutTitle={false}
          withoutMobileTitle={true}
          handlePrev={handlePrev}
        />
        <ModalForm open={orderData?.workflowResult != null}>
          <Grid px={{xs: 0, md: 2}}>
            <Typography
              data-testid="modal-modal-description"
              variant="subtitle1"
              component="h6"
            >
              <Trans i18nKey="delivery.finishAndPay.modalSuccessText">
                Your Parcel has been successfully created
                <br />
                Parcel tracking number is
              </Trans>
              <span data-testid="modal-modal-tracking-number">
                <b>{orderData?.workflowResult?.outputs?.trackingNumber}</b>
              </span>
            </Typography>
            <Link
              onClick={() => dispatch(resetOrder())}
              to={`../parcelShipments/${orderData?.workflowResult?.outputs?.orderId}`}
            >
              <Button
                data-testid="btn-ok-modal-modal"
                fullWidth
                variant="contained"
                color="secondary"
                sx={{mt: 3}}
              >
                OK
              </Button>
            </Link>
          </Grid>
        </ModalForm>
      </MainLayout>
    </>
  );
};
