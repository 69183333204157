import {toast} from "react-hot-toast";
import {useGoogleLogin} from "@react-oauth/google";
import {getPortalNameFromDomain} from "../../common/OrganizationConfig/useOrganizationConfig";
import i18next from "i18next";

export const useSignInWithGoogle = (googleSubmit: any) => {
  const signInWithGoogle = useGoogleLogin({
    flow: "auth-code",
    scope: "email profile openid",
    onSuccess: async (codeResponse) => {
      googleSubmit({
        grantType: "portal-user",
        redirectUri: window.location.origin,
        code: codeResponse.code,
        rememberMe: true,
        preferredLanguage: i18next?.language ?? "en",
        source:
          process.env.REACT_APP_PORTAL_NAME ??
          getPortalNameFromDomain() == "portal"
            ? "shopping"
            : "parcels",
      });
    },
    onError: (errorResponse) => {
      toast.error(errorResponse.error_description as string);
    },
  });

  return {signInWithGoogle};
};
