import {useCallback, useEffect} from "react";
import {RootState, useAppSelector} from "../../store";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../features/workflowTriggers/workflowExecution-api";
import {toastError} from "../../common/utils/toastMessages";
import {GetQuoteForRateValues} from "../../features/shipmentQuote/shipmentQuote-api";

export const useGetShoppingQuote = () => {
  const workflowId = useAppSelector(
    (state: RootState) =>
      state.organizationConfigState?.workflows?.getQuoteForRateWorkflowId,
  );

  const [runWorkflow, {isLoading, isError, error}] =
    useWorkflowExecutionMutation();

  const getQuote = useCallback(
    async (data: GetQuoteForRateValues) => {
      const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        workflowId: workflowId,
        values: {
          variables: {
            ...data,
          },
        },
      };

      try {
        return await runWorkflow(executeWorkflowApiArgs);
      } catch (err) {
        console.error(err);
        toastError(err);
      }
    },
    [runWorkflow, workflowId],
  );

  useEffect(() => {
    if (isError) {
      toastError(error);
    }
  }, [isLoading]);

  return {
    getQuote,
    isLoading,
    isError,
    error,
  };
};
