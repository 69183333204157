import {useAppSelector} from "../../store";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../features/workflowTriggers/workflowExecution-api";
import {useCallback, useEffect} from "react";
import {toastError} from "../../common/utils/toastMessages";
import {ChargeDto, OrderDto} from "../../features/order/order-api";
import {customValueIsTrue, getCustomValue} from "../../../utils/helper.utils";

export const useGetParcelQuote = () => {
  const orderData = useAppSelector((state) => state.parcelState);

  const thisOrder: any = orderData.order;

  const currentCustomerId = useAppSelector(
    (state) => state?.userState?.contactId,
  );

  const isPickupEnabled =
    useAppSelector(
      (state: any) =>
        state.organizationConfigState?.modules?.delivery?.enablePickup,
    ) === true;

  const config = useAppSelector(
    (state) => state?.organizationConfigState?.modules?.delivery,
  );

  const workflowId = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.workflows?.getOrderQuoteRoutesWorkflowId,
  );

  const [
    runWorkflow,
    {isLoading: isLoadingQuote, isError: isErrorQuote, error: errorQuote},
  ] = useWorkflowExecutionMutation();

  const runGetQuote = useCallback(() => {
    if (
      thisOrder &&
      thisOrder.contactValues?.contactId &&
      thisOrder.deliveryMethod?.rateId &&
      thisOrder.commodities
    ) {
      const order = {...thisOrder} as OrderDto;
      order.billToContactId = currentCustomerId;
      order.commodities = [
        {
          ...thisOrder.container,
          commodity: 0,
          description: "Box Container",
          pieces: 1,
          lastModified: new Date().toISOString(),
          created: new Date().toISOString(),
        },
      ];

      const finalMileAdditionalServices = {
        ...orderData.additionalServices,
      };

      const carrierPickupEnabled = customValueIsTrue(
        getCustomValue(
          orderData?.order?.deliveryToTrtMethod?.carrier?.customValues,
          "enable_pickup",
        ),
      );
      const carrierPickupAccountingItemCode = getCustomValue(
        orderData?.order?.deliveryToTrtMethod?.carrier?.customValues,
        "pickup_accounting_item_code",
      );
      if (
        isPickupEnabled &&
        orderData?.order?.deliveryMethod?.isPickup &&
        carrierPickupEnabled &&
        carrierPickupAccountingItemCode
      ) {
        finalMileAdditionalServices[carrierPickupAccountingItemCode] = 1;
      }

      const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        workflowId: workflowId,
        values: {
          variables: {
            order: order,
            finalMileRateId: thisOrder.deliveryMethod?.rateId,
            finalMileDestinationCountryCode:
              thisOrder.consigneeAddressValues?.countryCode,
            finalMileDestinationPostalCode:
              thisOrder.consigneeAddressValues?.postalCode,
            finalMileAdditionalServices,
          },
        },
      };

      return runWorkflow(executeWorkflowApiArgs).then((response: any) => {
        const data: any = response?.data?.outputs;
        const charges: ChargeDto[] = [];
        data.quotes?.forEach((quote: any) => {
          quote.results?.forEach((result: any) => {
            if (result.charges) {
              result.charges.forEach((charge: any) => {
                charges.push(charge);
              });
            }
          });
        });

        return charges;
      });
    }
  }, [
    thisOrder.contactValues?.contactId,
    orderData.additionalServices,
    thisOrder.consigneeAddressValues?.countryCode,
    thisOrder.deliveryMethod?.rateId,
    thisOrder.commodities,
    thisOrder.container,
    currentCustomerId,
    workflowId,
    runWorkflow,
    config,
  ]);

  useEffect(() => {
    if (isErrorQuote) {
      toastError(errorQuote);
    }
  }, [isLoadingQuote]);

  return {getQuote: runGetQuote};
};
