import React from "react";
import {Trans} from "react-i18next";
import ContentLayout from "../../../common/ContentLayout/components/contentLayout";
import MainLayout from "../../../common/MainLayout/components/mainLayout";
import PurchasesListForm from "../components";
import Grid from "@mui/material/Unstable_Grid2";
import ListAddButton from "../../../common/components/listAddButton";
import PurchasesFilter from "../components/purchasesFilter";
import {initOrder} from "../../../features/order/parcelShipment-slice";
import {useDispatch} from "react-redux";

export const createConsolidatedLink = "../delivery/0/";
export const createPurchaseLink = "../purchases/0";

const AllPurchases = () => {
  const dispatch = useDispatch();
  return (
    <MainLayout pageTitle="Purchases" backLink="../getting-started">
      <ContentLayout
        title={
          <Grid
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Trans i18nKey="titleYourPurchases">Your Purchases</Trans>
            <Grid container alignItems={"center"}>
              <ListAddButton
                i18nKey={"consolidatedShipment.createConsolidatedShipment"}
                title={"Create Consolidated Shipment"}
                link={createConsolidatedLink}
                customStyle={{marginRight: "19px"}}
                onClick={() => dispatch(initOrder({orderIds: [0]}))}
              />
              <ListAddButton
                i18nKey={"buttonAddPurchases"}
                title={"Add purchases"}
                link={createPurchaseLink}
              />
            </Grid>
          </Grid>
        }
        backLink="../getting-started"
        content={
          <PurchasesListForm
            createConsolidatedLink={createConsolidatedLink}
            createPurchaseLink={createPurchaseLink}
          />
        }
        mobileFilterComponent={<PurchasesFilter />}
        isPurchase={true}
      />
    </MainLayout>
  );
};

export default AllPurchases;
