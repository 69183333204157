import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Button,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Trans} from "react-i18next";
import {Link} from "react-router-dom";

type ContentLayoutProps = {
  content: any;
  title: any;
  backLink?: string;
  isPurchase?: boolean;
  withoutTitle?: boolean;
  withoutMobileTitle?: boolean;
  handlePrev?: any;
  mobileFilterComponent?: React.ReactElement | null;
  containerSx?: SxProps<Theme>;
  mobileBoxSx?: SxProps<Theme>;
  draftInfo?: React.ReactElement | null;
};

export default function ContentLayout({
  content,
  title,
  backLink,
  isPurchase = false,
  withoutTitle,
  withoutMobileTitle,
  handlePrev,
  mobileFilterComponent = null,
  containerSx = {},
  mobileBoxSx = {},
  draftInfo = null,
}: ContentLayoutProps) {
  const mobileBoxStyles = {
    border: "1px solid #D8E6FB",
    borderRadius: {xs: 0, md: "16px"},
    borderTopRightRadius: {xs: "16px"},
    borderTopLeftRadius: {xs: "16px"},
    paddingX: {xs: 2, md: 0},
    boxShadow: "0px 0px 8px rgba(15, 74, 132, 0.1)",
    backgroundColor: "white",
    minWidth: "100%",
    height: "100%",
    ...mobileBoxSx,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid
      sx={{height: "100%", width: {xs: "100vw", md: "auto"}, ...containerSx}}
    >
      <Grid>
        <Grid
          display={{xs: "none", md: "flex"}}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
          mt={4}
        >
          {backLink ? (
            <Button
              component={Link}
              to={backLink}
              color="inherit"
              startIcon={<ArrowBackIcon color="primary" />}
            >
              <Trans i18nKey="back">Back</Trans>
            </Button>
          ) : handlePrev ? (
            <Button
              onClick={handlePrev}
              color="inherit"
              startIcon={<ArrowBackIcon color="primary" />}
            >
              <Trans i18nKey="back">Back</Trans>
            </Button>
          ) : null}
          {draftInfo}
        </Grid>
        {!isDesktop && mobileFilterComponent && (
          <Grid mt={{md: 4, xs: -2}}>{mobileFilterComponent}</Grid>
        )}
        {((isDesktop && !withoutTitle && !isPurchase) ||
          (!withoutMobileTitle && !isPurchase && !isDesktop) ||
          (isPurchase && isDesktop && !withoutTitle)) && (
          <Grid
            mt={{md: 4, xs: 0}}
            ml={{md: 0, xs: 2}}
            sx={{textAlign: "left"}}
          >
            <Typography
              variant={isDesktop ? "h1" : "h3"}
              mb={3}
              sx={{lineHeight: "32px", textTransform: "capitalize"}}
            >
              {title}
            </Typography>
          </Grid>
        )}
      </Grid>
      {isDesktop && <Grid>{content}</Grid>}
      {!isDesktop && (
        <Grid
          sx={{
            display: {xs: "block", md: "none"},
            height: "100%",
          }}
        >
          <Box sx={mobileBoxStyles}>{content}</Box>
        </Grid>
      )}
    </Grid>
  );
}
