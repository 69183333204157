import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Form, Formik, FormikProps} from "formik";
import {getDeliveryAddressValidationSchema} from "../../../Delivery/pages/validation";
import ToAddressComponent from "../../../Delivery/DeliveryAddress/components/toAddressComponent";
import {
  AddressCardValues,
  ContactCategory,
  DeliveryAddressCommandValues,
  DeliveryAddressFormFields,
  DeliveryAddressFormProps,
} from "../../../Delivery/DeliveryAddress/components/interfaces";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ImageList,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {RootState, useAppSelector} from "../../../store";
import {toast} from "react-hot-toast";
import {
  useContactAddressDeleteContactAddressMutation,
  useContactAddressUpdateContactAddressMutation,
} from "../../../features/contactAddress/contactAddress-api";
import {
  ContactAddressDto,
  ContactDto,
  useContactCreateContactMutation,
  useLazyContactGetChildContactsQuery,
} from "../../../features/contact/contact-api";
import {toastError} from "../../../common/utils/toastMessages";
import AddressCard from "../../../common/components/addressCard";
import AddAddressCard from "../../../common/components/addAddressCard";
import {UserContactAddressInfo} from "../../../UserProfile/AccountSavedAddresses/components";
import {PageFragments} from "../../../UserProfile/AccountSavedAddresses/pages";
import DeleteContactAddressModal from "../../../UserProfile/AccountSavedAddresses/components/DeleteContactAddressModal";
import ModalForm from "../../../common/Modal/components/modalForm";
import {getInitialValues} from "./helpers";
import DoubleCard from "../../../common/components/doubleCard";
import PickupLocationComponent from "./pickupLocationComponent";
import {getLangCodeFromI18n} from "../../../app/app.component";
import {getLocalizedToponyms} from "../../../ParcelShipments/AllParcelShipments/utils/parcelShipmentTable.utils";
import {getValidationSchemaRequiredFields} from "../../../common/hooks/useFormRequiredFields";
import {CountryFilter} from "../../../common/hooks/useGetCountries";
import {useFileUploader} from "../../../common/hooks/useFileUploader";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../../features/workflowTriggers/workflowExecution-api";
import {isContactAddressPassportRequired} from "./deliveryAddressFragment";
import {AttachmentDto} from "../../../features/attachment/attachment-api";
import {ImageWithModal} from "../../../common/components/imageWithModal";
import {customValueIsTrue} from "../../../../utils/helper.utils";
import {trimStringValues} from "../../../../utils/string.utils";

const CONTACT_ADDRESS_INITIAL_VALUES: UserContactAddressInfo = {
  toAddressFirstName: "",
  toAddressLastName: "",
  toAddressPhoneNumber: "",
  toAddressEmail: "",
  toAddressCountryCode: "",
  toAddressCountryName: "",
  toAddressRegionCode: "",
  toAddressRegionName: "",
  toAddressDistrict: "",
  toAddressCity: "",
  toAddressPostalCode: "",
  toAddressStreetName: "",
  toAddressHouseNumber: "",
  toAddressApartment: "",
  toAddressSetAsDefault: false,
  toAddressContactCategory: ContactCategory.Individual,
  toAddressCompanyName: "",
  pickupLocationFirstName: "",
  pickupLocationLastName: "",
  pickupLocationPhoneNumber: "",
  pickupLocationEmail: "",
  pickupLocationCountryCode: "",
  pickupLocationCountryName: "",
  pickupLocationDistrict: "",
  pickupLocationContactAddressId: null,
  pickupLocationPostOffices: true,
  pickupLocationParcelLockers: true,
  pickupLocationAddressLine: "",
  pickupLocationContactCategory: ContactCategory.Individual,
  pickupLocationCompanyName: "",
  contactId: null,
  contactAddressId: null,
  addressLine: "",
  isPickupLocation: null,
};

export enum SelectedTabEnum {
  left = "left",
  right = "right",
}

const deliveryAddressFormInitialValues = getInitialValues();

export default function DeliveryAddressForm({
  pageFragment,
  editingAddress,
  setCurrentDeliveryAddress,
  setCurrentDeliveryAddressId,
  setEditingWidth,
  onChangePageState = () => 0,
  setEditingAddress,
  setCurrentDeliveryAddressFormDto,
  getContactAddress,
  config,
}: DeliveryAddressFormProps) {
  const lang = getLangCodeFromI18n();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const {t} = useTranslation();
  const userState = useAppSelector((state) => state.userState);
  const passportRequired =
    useAppSelector(
      (state: RootState) =>
        state?.organizationConfigState?.modules?.delivery?.passportRequired,
    ) === true;

  const [currentAddress, setCurrentAddress] =
    useState<UserContactAddressInfo | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [contactAddresses, setContactAddresses] =
    useState<(UserContactAddressInfo & AddressCardValues)[] | null>(null);
  const [triggerUpdateAddresses, setTriggerUpdateAddresses] =
    useState<boolean>(false);
  const [removeAddressModalOpen, setRemoveAddressModalOpen] =
    useState<boolean>(false);
  const [addressToRemove, setAddressToRemove] =
    useState<UserContactAddressInfo | null>(null);
  const [backToShipRequest, setBackToShipRequest] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState(SelectedTabEnum.left);
  const [passportAttachments, setPassportAttachments] = useState<
    AttachmentDto[]
  >([]);

  const [getChildContacts] = useLazyContactGetChildContactsQuery();
  const [createContact] = useContactCreateContactMutation();
  const [updateContactAddress] =
    useContactAddressUpdateContactAddressMutation();
  const [deleteContactAddress] =
    useContactAddressDeleteContactAddressMutation();
  const [validationSchema, setValidationSchema] = useState(
    getDeliveryAddressValidationSchema("toAddress", t),
  );

  const handleSubmitError = (error: any) => {
    toastError(error);
  };
  const handleGetContactsError = (error: any): ContactDto[] => {
    toastError(error);
    return [];
  };

  const getUserContacts = async (): Promise<ContactDto[]> => {
    if (!userState?.contactId) return [];

    try {
      const getChildContactsResponse = (await getChildContacts({
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        contactId: userState?.contactId,
        filter: config?.deliveryAddressesFilter ?? "addressType:NOT Billing",
      })) as any;
      if (getChildContactsResponse?.error || !getChildContactsResponse?.data) {
        return handleGetContactsError(
          new Error(
            getChildContactsResponse?.error || "Failed to get child contacts",
          ),
        );
      }

      const userContacts: ContactDto[] = [];
      if (getChildContactsResponse.data?.items?.length) {
        userContacts.push(...getChildContactsResponse.data.items);
      }

      return userContacts;
    } catch (error) {
      return handleGetContactsError(error as Error);
    }
  };

  const mapContactsToFormValues = (
    contactAddresses: ContactAddressDto[],
  ): (UserContactAddressInfo & AddressCardValues)[] => {
    const userContactAddresses: (UserContactAddressInfo & AddressCardValues)[] =
      [];
    if (contactAddresses?.length) {
      for (const contactAddress of contactAddresses) {
        const [
          localizedCountryName,
          localizedStateName,
          localizedCityName,
          localizedAddressName,
        ] = getLocalizedToponyms(contactAddress, lang);

        userContactAddresses.push({
          ...CONTACT_ADDRESS_INITIAL_VALUES,
          ...{
            name:
              contactAddress?.contact?.customValues?.contactCategory ===
              ContactCategory.Business
                ? contactAddress?.contact?.name
                : `${contactAddress.contact?.contactFirstName} ${contactAddress.contact?.contactLastName}`,
            contactName: `${contactAddress.contact?.contactFirstName} ${contactAddress.contact?.contactLastName}`,
            addressLine1: `${localizedAddressName}`,
            addressLine2: `${contactAddress.addressLine2 ?? ""}`,
            houseNumber: `${
              contactAddress.customValues?.toAddressHouseNumber ?? ""
            }`,
            city: localizedCityName,
            district: contactAddress.customValues?.["toAddressDistrict"],
            region: localizedStateName,
            postalCode: contactAddress.postalCode,
            country: localizedCountryName,
            countryCustomValues: contactAddress?.country?.customValues,
            phoneNumber: contactAddress?.contact?.phoneNumber,
            contactCustomValues: contactAddress?.contact?.customValues,
          },
          ...(contactAddress.customValues?.is_pickup_location
            ? {
                pickupLocationFirstName:
                  contactAddress?.contact?.contactFirstName || "",
                pickupLocationLastName:
                  contactAddress?.contact?.contactLastName || "",
                pickupLocationPhoneNumber:
                  contactAddress?.contact?.phoneNumber || "",
                pickupLocationEmail:
                  contactAddress?.contact?.emailAddress || "",
                pickupLocationCountryCode: contactAddress?.countryCode || "",
                pickupLocationCountryName: localizedCountryName || "",
                pickupLocationContactAddressId:
                  contactAddress?.contactAddressId || null,
                pickupLocationPostOffices: true,
                pickupLocationParcelLockers: true,
                pickupLocationAddressLine: localizedAddressName || "",
                contactId: contactAddress?.contactId,
                contactAddressId: contactAddress?.contactAddressId,
                addressLine: localizedAddressName || "",
                isPickupLocation: true,
                pickupLocationContactCategory: contactAddress?.contact
                  ?.customValues?.contactCategory as ContactCategory,
                pickupLocationCompanyName: contactAddress?.contact?.name ?? "",
              }
            : {
                toAddressFirstName:
                  contactAddress?.contact?.contactFirstName || "",
                toAddressLastName:
                  contactAddress?.contact?.contactLastName || "",
                toAddressPhoneNumber:
                  contactAddress?.contact?.phoneNumber || "",
                toAddressEmail: contactAddress?.contact?.emailAddress || "",
                toAddressCountryCode: contactAddress?.countryCode || "",
                toAddressCountryName: localizedCountryName || "",
                toAddressRegionCode: contactAddress?.stateCode || "",
                toAddressRegionName: localizedStateName || "",
                toAddressDistrict:
                  contactAddress?.customValues?.toAddressDistrict || "",
                toAddressCity: contactAddress?.cityName || "",
                toAddressPostalCode: contactAddress?.postalCode || "",
                toAddressStreetName: contactAddress?.addressLine || "",
                toAddressHouseNumber:
                  contactAddress?.customValues?.toAddressHouseNumber || "",
                toAddressApartment: contactAddress?.addressLine2 || "",
                toAddressSetAsDefault: false,
                contactId: contactAddress?.contactId,
                contactAddressId: contactAddress?.contactAddressId,
                addressLine: contactAddress?.addressLine || "",
                isPickupLocation: false,
                toAddressContactCategory: contactAddress?.contact?.customValues
                  ?.contactCategory as ContactCategory,
                toAddressCompanyName: contactAddress?.contact?.name ?? "",
              }),
        });
      }
    }

    return userContactAddresses;
  };

  const handleAddAddress = () => {
    onChangePageState(PageFragments.AddingAddress);
    setCurrentAddress(CONTACT_ADDRESS_INITIAL_VALUES);
    window.scrollTo(0, 0);
  };

  const handleEditAddress = (contactAddress: UserContactAddressInfo) => {
    setEditingAddress(contactAddress);
    onChangePageState(
      contactAddress.isPickupLocation
        ? PageFragments.EditingPickupLocation
        : PageFragments.EditingAddress,
    );
    setCurrentAddress(contactAddress);
    window.scrollTo(0, 0);
  };

  const handleRemoveAddress = (contactAddress: UserContactAddressInfo) => {
    setAddressToRemove(contactAddress);
    setRemoveAddressModalOpen(true);
  };

  const handleRemoveAddressConfirm = async () => {
    if (!addressToRemove) return;

    const contactAddress = addressToRemove;
    if (isSubmitting) return;

    setIsSubmitting(true);
    try {
      const deleteContactAddressResponse = await deleteContactAddress({
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        contactAddressId: contactAddress.contactAddressId as number,
        deleteContactAddressCommandValues: {
          contactId: contactAddress.contactId as number,
          customerId: userState.contactId as number,
          isDefault: false,
          newDefaultId: null,
        },
      });

      if ("error" in deleteContactAddressResponse) {
        handleSubmitError(deleteContactAddressResponse.error);
        return;
      }

      toast.success(t("toasts.addressWasDeleted"));
      setTriggerUpdateAddresses((prevState) => !prevState);
    } catch (error) {
      handleSubmitError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCreateNewContact = (
    values: DeliveryAddressCommandValues,
    isPickupLocation?: boolean,
  ): Promise<{
    data?: {contactId: number; contactAddresses: any[]};
    error?: any;
  }> => {
    return createContact({
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      createContactCommand: {
        divisionId: process.env
          .REACT_APP_PORTAL_DIVISION_ID as unknown as number,
        values:
          values.pickupLocationContactAddressId && isPickupLocation
            ? {
                name:
                  values?.pickupLocationContactCategory ===
                  ContactCategory.Business
                    ? values.pickupLocationCompanyName
                    : `${values.pickupLocationFirstName} ${values.pickupLocationLastName}`,
                firstName: values.pickupLocationFirstName,
                lastName: values.pickupLocationLastName,
                phoneNumber: values.pickupLocationPhoneNumber,
                emailAddress: values.pickupLocationEmail,
                contactType: "Contact",
                parentContactId: userState.contactId,
                setAsDefault: false,
                customValues: {
                  contactCategory: values?.pickupLocationContactCategory,
                },
                shippingAddress: {
                  contactAddressId: values.pickupLocationContactAddressId,
                  customValues: values.pickupLocationContactAddressId
                    ? {
                        forwardingAgentAddressId:
                          values.pickupLocationContactAddressId.toString(),
                      }
                    : null,
                },
              }
            : {
                name:
                  values?.toAddressContactCategory === ContactCategory.Business
                    ? values.toAddressCompanyName
                    : `${values.toAddressFirstName} ${values.toAddressLastName}`,
                firstName: values.toAddressFirstName,
                lastName: values.toAddressLastName,
                phoneNumber: values.toAddressPhoneNumber,
                emailAddress: values.toAddressEmail,
                contactType: "Contact",
                parentContactId: userState.contactId,
                setAsDefault:
                  values.toAddressSetAsDefault ||
                  contactAddresses?.length === 0,
                customValues: {
                  contactCategory: values?.toAddressContactCategory,
                },
                shippingAddress: {
                  countryCode: values.toAddressCountryCode || "",
                  regionCode: values.toAddressRegionCode || "",
                  cityName: values.toAddressCity || "",
                  postalCode: values.toAddressPostalCode || "",
                  streetName: values.toAddressStreetName || "",
                  apartment: values.toAddressApartment || "",
                  customValues: {
                    toAddressHouseNumber: values?.toAddressHouseNumber,
                    toAddressDistrict: values?.toAddressDistrict,
                  },
                },
              },
      },
    }) as Promise<{data?: any; error?: any}>;
  };

  const handleUpdateContactAddress = (
    values: DeliveryAddressCommandValues,
  ): Promise<{data?: {contactId: number}; error?: any}> => {
    return updateContactAddress({
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      contactAddressId: currentAddress?.contactAddressId || 0,
      updateContactAddressCommandValues: {
        contactName:
          values?.toAddressContactCategory === ContactCategory.Business
            ? values.toAddressCompanyName
            : `${values.toAddressFirstName} ${values.toAddressLastName}`,
        contactCategory: values?.toAddressContactCategory,
        addressLine: values.toAddressStreetName || "",
        addressLine2: values.toAddressApartment || "",
        cityName: values.toAddressCity || "",
        countryCode: values.toAddressCountryCode || "",
        postalCode: values.toAddressPostalCode || "",
        stateCode: values.toAddressRegionCode || "",
        firstName: values.toAddressFirstName || "",
        lastName: values.toAddressLastName || "",
        phoneNumber: values.toAddressPhoneNumber || "",
        emailAddress: values.toAddressEmail || "",
        customValues: {
          toAddressHouseNumber: values?.toAddressHouseNumber,
          toAddressDistrict: values?.toAddressDistrict,
        },
      },
    }) as Promise<{data?: any; error?: any}>;
  };

  const addPassportAttachmentWorkflowId = useAppSelector(
    (state: RootState) =>
      state.organizationConfigState?.workflows?.addPassportAttachmentWorkflowId,
  );
  const [runWorkflowCreatePassportAttachments] = useWorkflowExecutionMutation();

  const handleCreatePassportAttachmentToChildContact = useCallback(
    async (fileName: string, fileUrl: string, contactId: number) => {
      const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        workflowId: addPassportAttachmentWorkflowId,
        values: {
          variables: {
            fileUrl,
            fileName,
            contactId: contactId?.toString(),
            customerId: userState?.contactId,
          },
        },
      };

      const addPassportAttachmentResponse: any =
        await runWorkflowCreatePassportAttachments(executeWorkflowApiArgs);

      if (addPassportAttachmentResponse?.error) {
        toastError(addPassportAttachmentResponse.error);
        return;
      }
    },
    [addPassportAttachmentWorkflowId],
  );

  const handleSubmit = async (values: DeliveryAddressCommandValues) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const isCreatingNewAddress = !currentAddress?.contactAddressId;
      let contactAddressId;
      let contactId;
      let contactAddress;

      values = trimStringValues(values);

      if (isCreatingNewAddress) {
        const createContactResponse = await handleCreateNewContact(
          values,
          selectedTab === SelectedTabEnum.right,
        );
        if (createContactResponse?.error) {
          handleSubmitError(createContactResponse?.error);
          return;
        }

        contactAddress = createContactResponse?.data?.contactAddresses?.find(
          (address) => address.addressType === "Shipping",
        );
        contactAddressId = contactAddress.contactAddressId;
        contactId = contactAddress.contactId;
      } else {
        const updateContactAddressResponse = await handleUpdateContactAddress(
          values,
        );
        if (updateContactAddressResponse?.error) {
          handleSubmitError(updateContactAddressResponse?.error);
          return;
        }
      }
      toast.success(
        isCreatingNewAddress
          ? t("toasts.addressWasAdded")
          : t("toasts.addressWasUpdated"),
      );
      setTriggerUpdateAddresses((prevState) => !prevState);
      setCurrentAddress(contactAddress);
      if (contactAddressId) {
        values.contactAddressId = contactAddressId;
        values.contactId = contactId;
      }

      if (values.passportData) {
        await handleCreatePassportAttachmentToChildContact(
          values.passportData?.file?.name,
          values.passportData.url,
          contactId ?? values?.contactId,
        );
      }

      if (backToShipRequest) {
        if (isCreatingNewAddress)
          setCurrentDeliveryAddressId(contactAddressId ?? null);

        setCurrentDeliveryAddressFormDto(undefined);
        getContactAddress(Number(values.contactAddressId)).then((result) => {
          if (result) setCurrentDeliveryAddressFormDto(result);
        });
      } else {
        setCurrentDeliveryAddressId(null);
        setCurrentDeliveryAddressFormDto(null);
        setBackToShipRequest(true);
      }
      window.scroll(0, 0);
    } catch (error) {
      handleSubmitError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (currentAddress) setEditingWidth(true);
    else setEditingWidth(false);
  }, [currentAddress]);

  useEffect(() => {
    getUserContacts().then((contacts) => {
      setContactAddresses(mapContactsToFormValues(contacts));
    });
  }, [userState?.contactId, triggerUpdateAddresses, lang]);

  useEffect(() => {
    if (pageFragment === 3) {
      setCurrentAddress(CONTACT_ADDRESS_INITIAL_VALUES);
    } else if ((pageFragment === 4 || pageFragment === 6) && editingAddress) {
      editingAddress.toAddressSetAsDefault = false;
      setCurrentAddress(editingAddress);
    } else {
      setCurrentAddress(null);
    }
  }, [pageFragment]);

  const getPassportAttachmentsWorkflowId = useAppSelector(
    (state: RootState) =>
      state.organizationConfigState?.workflows
        ?.getPassportAttachmentsWorkflowId,
  );
  const [runWorkflowGetPassportAttachments] = useWorkflowExecutionMutation();

  const handleGetPassportAttachments = useCallback(
    async (customerId: number, contactId: number) => {
      const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        workflowId: getPassportAttachmentsWorkflowId,
        values: {
          variables: {
            contactId: contactId?.toString(),
            customerId: userState?.contactId,
          },
        },
      };

      const getPassportAttachmentResponse: any =
        await runWorkflowGetPassportAttachments(executeWorkflowApiArgs);

      if (getPassportAttachmentResponse?.error) {
        toastError(getPassportAttachmentResponse.error);
        return null;
      }

      const attachments =
        getPassportAttachmentResponse?.data?.outputs?.attachments;
      setPassportAttachments(attachments);

      return attachments;
    },
    [getPassportAttachmentsWorkflowId],
  );

  useEffect(() => {
    if (currentAddress?.contactId && userState?.contactId && passportRequired) {
      handleGetPassportAttachments(
        userState?.contactId,
        currentAddress?.contactId,
      );
    }
  }, [currentAddress, handleGetPassportAttachments, passportRequired]);

  const validationSchemaRequiredFields = useMemo(() => {
    return getValidationSchemaRequiredFields(validationSchema);
  }, [validationSchema]);

  const leftContent = (formikProps: any) => {
    if (selectedTab == SelectedTabEnum.left)
      return (
        <ToAddressComponent
          key={"deliveryAddress"}
          showMyAddresses={false}
          formikProps={formikProps as FormikProps<DeliveryAddressFormFields>}
          disabled={isSubmitting}
          showSaveAddressCheckbox={false}
          initialActiveCountry={currentAddress?.toAddressCountryCode ?? null}
          setValidationSchema={setValidationSchema}
          setCurrentAddress={setCurrentAddress}
          selectedTab={selectedTab}
          phoneNumberDefaultCountryCode={"ua"}
          requiredFields={validationSchemaRequiredFields}
          countryFilter={CountryFilter.Delivery}
        />
      );
    return null;
  };

  const rightContent = (formikProps: any) => {
    if (selectedTab == SelectedTabEnum.right)
      return (
        <PickupLocationComponent
          key={"deliveryAddress"}
          formikProps={formikProps as FormikProps<DeliveryAddressFormFields>}
          initialActiveCountry={
            currentAddress?.pickupLocationCountryCode ?? null
          }
          initialActiveMarkerId={
            currentAddress?.pickupLocationContactAddressId ?? null
          }
          disabled={isSubmitting}
          setValidationSchema={setValidationSchema}
          selectedTab={selectedTab}
          phoneNumberDefaultCountryCode="ua"
          requiredFields={validationSchemaRequiredFields}
          countryFilter={CountryFilter.Delivery}
        />
      );
    return null;
  };

  const [isPassportUploadAgain, setIsPassportUploadAgain] = useState(false);
  const i18nPassportPrefix = "delivery.passport.";

  const renderForm = useCallback(
    (formikProps: FormikProps<any>) => {
      const {uploadFileComponent: uploadPassportComponent} = useFileUploader({
        onUploadSuccess: (result) => {
          formikProps.setFieldValue("passportData", result);
          setPassportAttachments([]);
          setIsPassportUploadAgain(false);
        },
        onCancel: () => {
          formikProps.setFieldValue("passportData", null);
        },
      });

      const countryPassportRequired =
        passportRequired &&
        (formikProps?.values?.countryCustomValues?.passportRequired === true ||
          formikProps?.values?.countryCustomValues?.passportRequired ===
            "true");

      const isPassportUploaded =
        customValueIsTrue(
          formikProps?.values?.contactCustomValues?.passportUploaded,
        ) || !!formikProps?.values?.passportData;

      const isPassportValid =
        (countryPassportRequired && isPassportUploaded) ||
        !countryPassportRequired;

      return (
        <Form>
          <DoubleCard
            variant={isDesktop ? "normal" : "small"}
            leftLabel={t("delivery.address.addresses")}
            leftContent={leftContent(formikProps)}
            rightLabel={t("delivery.address.pickupLocations")}
            rightContent={rightContent(formikProps)}
            initialSelected={pageFragment === 6 ? 2 : 1}
            bgColorActive="white"
            bgColorInactive="#D8E6FB"
            lineColor="primary.main"
            disableRight={currentAddress?.isPickupLocation === false}
            onClickLeft={() => {
              setSelectedTab(SelectedTabEnum.left);
              setValidationSchema(
                getDeliveryAddressValidationSchema("toAddress", t),
              );
              setEditingWidth(true);
            }}
            onClickRight={() => {
              setSelectedTab(SelectedTabEnum.right);
              setValidationSchema(
                getDeliveryAddressValidationSchema("pickupLocation", t),
              );
              setEditingWidth(true);
            }}
          />

          {uploadPassportComponent && countryPassportRequired && (
            <Grid my={3}>
              {isPassportUploaded && !isPassportUploadAgain ? (
                <Grid>
                  <Grid display={"flex"} alignItems={"center"}>
                    <CheckCircleIcon
                      color={"primary"}
                      sx={{fontSize: "32px", marginRight: "10px"}}
                    />
                    <Typography variant={"h2"}>
                      <Trans i18nKey={i18nPassportPrefix + "passportUploaded"}>
                        Passport uploaded
                      </Trans>
                      &nbsp;&nbsp;
                      <Link
                        sx={{cursor: "pointer"}}
                        onClick={() => setIsPassportUploadAgain(true)}
                      >
                        <Trans i18nKey={i18nPassportPrefix + "uploadAgain"}>
                          Upload again
                        </Trans>
                      </Link>
                    </Typography>
                  </Grid>
                  <ImageList cols={5} gap={16}>
                    {passportAttachments?.map((item: AttachmentDto) => (
                      <ImageWithModal
                        key={item.attachmentId}
                        item={{
                          uri: item.presignedPreviewUri ?? "",
                          description: item?.description,
                        }}
                      />
                    ))}
                  </ImageList>
                </Grid>
              ) : (
                <Grid display={"flex"} flexDirection={"column"} gap={2}>
                  <Typography variant={"h2"}>
                    <Trans i18nKey={i18nPassportPrefix + "uploadPassportScan"}>
                      Upload Passport Scan
                    </Trans>
                  </Typography>
                  <Typography variant={"h4"}>
                    <Trans i18nKey={i18nPassportPrefix + "requirement"}>
                      Please upload Receiver’s passport scan or photo
                      <br />
                      to simplify customs clearance in the destination country
                    </Trans>
                  </Typography>
                  {uploadPassportComponent}
                </Grid>
              )}
            </Grid>
          )}

          <Grid xs sx={{margin: "20px 0"}}>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              disabled={
                isSubmitting || !formikProps.isValid || !isPassportValid
              }
              data-testid="btn-submit"
              color="secondary"
            >
              <Trans i18nKey={"btnContinue"}>Continue</Trans>
            </LoadingButton>
          </Grid>
        </Form>
      );
    },
    [
      isSubmitting,
      currentAddress,
      selectedTab,
      passportAttachments,
      isPassportUploadAgain,
      setIsPassportUploadAgain,
      i18nPassportPrefix,
      validationSchemaRequiredFields,
    ],
  );

  return currentAddress ? (
    <Grid
      sx={{marginX: isDesktop ? "auto" : "-16px"}}
      container
      columns={{xs: 6, md: 12}}
    >
      <Grid xs={6} md={12}>
        <Formik
          initialValues={{
            ...deliveryAddressFormInitialValues,
            ...currentAddress,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => renderForm(formikProps)}
        </Formik>
      </Grid>
    </Grid>
  ) : (
    <Grid
      spacing={isDesktop ? 5 : 2}
      justifyContent={isDesktop ? "flex-start" : "center"}
      container
      columns={{xs: 6, md: 12}}
      sx={{
        "& .MuiCard-root": {
          height: "100%",
        },
      }}
    >
      <Grid xs={6} md={6} lg={4} height={isDesktop ? "initial" : "max-content"}>
        <AddAddressCard
          handleAddAddress={handleAddAddress}
          isDesktop={isDesktop}
        />
      </Grid>
      {contactAddresses?.map((contactAddress, index) => {
        return (
          <Grid
            xs={6}
            md={6}
            lg={4}
            key={index}
            display={
              isSubmitting &&
              contactAddress.contactAddressId ===
                addressToRemove?.contactAddressId
                ? "flex"
                : "block"
            }
            alignItems={"center"}
            justifyContent={"center"}
          >
            <AddressCard
              index={index}
              name={contactAddress.name}
              contactName={contactAddress.contactName}
              phoneNumber={contactAddress.phoneNumber}
              addressLine1={contactAddress.addressLine1}
              addressLine2={contactAddress.addressLine2}
              houseNumber={contactAddress.houseNumber}
              district={contactAddress.district}
              region={contactAddress.region}
              city={contactAddress.city}
              country={contactAddress.country}
              postalCode={contactAddress.postalCode}
              onClick={() => {
                window.scroll(0, 0);
                setCurrentDeliveryAddress(contactAddress);
              }}
              onEditClick={() => handleEditAddress(contactAddress)}
              onRemoveClick={() => handleRemoveAddress(contactAddress)}
              isDesktop={isDesktop}
              isPickupLocation={contactAddress.isPickupLocation}
              setBackToShipRequest={setBackToShipRequest}
              isLoading={
                isSubmitting &&
                contactAddress.contactAddressId ===
                  addressToRemove?.contactAddressId
              }
              isPassportRequired={isContactAddressPassportRequired(
                contactAddress,
                passportRequired,
              )}
            />
          </Grid>
        );
      })}
      <ModalForm open={removeAddressModalOpen}>
        <DeleteContactAddressModal
          handleClose={() => setRemoveAddressModalOpen(false)}
          handleConfirm={() => {
            handleRemoveAddressConfirm();
            setRemoveAddressModalOpen(false);
          }}
        />
      </ModalForm>
    </Grid>
  );
}
