/* istanbul ignore file */

import React, {Suspense} from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {GoogleOAuthProvider} from "@react-oauth/google";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import App from "./modules/app/app.component";
import {persistor, store} from "./modules/store";
import {GOOGLE_OAUTH_ID} from "./properties";
import {PersistGate} from "redux-persist/integration/react";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <GoogleOAuthProvider clientId={GOOGLE_OAUTH_ID}>
            <Suspense fallback="...is loading">
              <App />
            </Suspense>
          </GoogleOAuthProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

serviceWorkerRegistration.register();

reportWebVitals(console.log);
