import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../../features/workflowTriggers/workflowExecution-api";
import {useCallback, useEffect} from "react";
import {toastError} from "../../../common/utils/toastMessages";

export const useCheckAvailabilityWorkflow = (
  workflowId: string | null | undefined,
) => {
  if (!workflowId) return {};

  const [runWorkflow, {isLoading, isError, error}] =
    useWorkflowExecutionMutation();

  const checkAvailability = useCallback(
    async ({contactAddressId, customerCloseTime, dispatchDate}: any) => {
      if (!workflowId || !contactAddressId) return;

      const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        workflowId: workflowId,
        values: {
          variables: {
            contactAddressId,
            customerCloseTime,
            dispatchDate,
          },
        },
      };

      const workflowResult: any = await runWorkflow(executeWorkflowApiArgs);

      return workflowResult?.data?.outputs?.checkAvailabilityResponse?.options;
    },
    [runWorkflow, workflowId],
  );

  useEffect(() => {
    if (isError) {
      toastError(error);
    }
  }, [isLoading]);

  return {checkAvailability, isLoading, isError, error};
};
