import React, {useEffect, useState} from "react";
import {useMediaQuery, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2";
import UserAddressesForm from "./userAddressesForm";
import {
  ContactAddressDto,
  ContactDto,
  ContactGetContactApiArg,
  useContactGetContactMutation,
  useLazyContactGetChildContactsQuery,
} from "../../../features/contact/contact-api";
import {useAppSelector} from "../../../store";
import {PageFragments} from "../pages";
import {toastError} from "../../../common/utils/toastMessages";
import {
  AddressCardValues,
  ContactAddressValues,
  ContactCategory,
  PickupLocationValues,
} from "../../../Delivery/DeliveryAddress/components/interfaces";
import {getLangCodeFromI18n} from "../../../app/app.component";
import {getLocalizedToponyms} from "../../../ParcelShipments/AllParcelShipments/utils/parcelShipmentTable.utils";

export type UserContactAddressInfo = ContactAddressValues &
  PickupLocationValues & {
    contactId?: number | null;
    contactAddressId?: number | null;
    addressLine?: string | null;
    isPickupLocation?: boolean | null;
    contactCustomValues?: any;
    countryCustomValues?: any;
  };

interface AccountSavedAddressesFormProps {
  onChangePageState: (pageState: PageFragments) => void;
  triggerHandleBack?: boolean;
  triggerContactsRefetch?: boolean;
}

const CONTACT_ADDRESS_INITIAL_VALUES: UserContactAddressInfo = {
  toAddressFirstName: "",
  toAddressLastName: "",
  toAddressPhoneNumber: "",
  toAddressEmail: "",
  toAddressCountryCode: "",
  toAddressCountryName: "",
  toAddressRegionCode: "",
  toAddressRegionName: "",
  toAddressDistrict: "",
  toAddressCity: "",
  toAddressPostalCode: "",
  toAddressStreetName: "",
  toAddressHouseNumber: "",
  toAddressApartment: "",
  toAddressSetAsDefault: false,
  toAddressContactCategory: ContactCategory.Individual,
  toAddressCompanyName: "",
  pickupLocationFirstName: "",
  pickupLocationLastName: "",
  pickupLocationPhoneNumber: "",
  pickupLocationEmail: "",
  pickupLocationCountryCode: "",
  pickupLocationCountryName: "",
  pickupLocationDistrict: "",
  pickupLocationContactAddressId: null,
  pickupLocationPostOffices: true,
  pickupLocationParcelLockers: true,
  pickupLocationAddressLine: "",
  pickupLocationContactCategory: ContactCategory.Individual,
  pickupLocationCompanyName: "",
  contactId: null,
  contactAddressId: null,
  addressLine: "",
  isPickupLocation: null,
};

export default function AccountSavedAddressesForm({
  onChangePageState,
  triggerHandleBack = undefined,
  triggerContactsRefetch = undefined,
}: AccountSavedAddressesFormProps) {
  const lang = getLangCodeFromI18n();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const {t} = useTranslation();
  const userState = useAppSelector((state) => state.userState);

  const [contactAddresses, setContactAddresses] =
    useState<(UserContactAddressInfo & AddressCardValues)[] | null>(null);
  const [editingWidth, setEditingWidth] = useState<boolean>(false);
  const [isToAddressComponent, setIsToAddressComponent] =
    useState<boolean>(false);
  const [defaultContactAddressId, setDefaultContactAddressId] =
    useState<number>(0);
  const [getChildContacts] = useLazyContactGetChildContactsQuery();
  const [getContact] = useContactGetContactMutation();

  const GetContact = async (request: number | null | undefined) => {
    const commandArgs: ContactGetContactApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      contactId: request || 0,
    };
    return getContact(commandArgs);
  };

  const [triggerUpdateAddresses, setTriggerUpdateAddresses] =
    useState<boolean>(false);
  const handleUpdateAddresses = () => {
    setTriggerUpdateAddresses((prevState) => !prevState);
  };

  const handleGetContactsError = (error: any): ContactDto[] => {
    toastError(error);
    return [];
  };

  const getUserContacts = async (): Promise<ContactDto[]> => {
    if (!userState?.contactId) return [];

    try {
      const getChildContactsResponse = (await getChildContacts({
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        contactId: userState?.contactId,
        filter: "addressType:NOT Billing",
      })) as any;
      if (getChildContactsResponse?.error || !getChildContactsResponse?.data) {
        return handleGetContactsError(
          new Error(
            getChildContactsResponse?.error || "Failed to get child contacts",
          ),
        );
      }

      const userContacts: ContactDto[] = [];
      if (getChildContactsResponse.data?.items?.length) {
        userContacts.push(...getChildContactsResponse.data.items);
      }

      return userContacts;
    } catch (error) {
      return handleGetContactsError(error as Error);
    }
  };

  const mapContactsToFormValues = (
    contactAddresses: ContactAddressDto[],
  ): UserContactAddressInfo[] => {
    const userContactAddresses: UserContactAddressInfo[] = [];
    if (contactAddresses?.length) {
      for (const contactAddress of contactAddresses) {
        const [
          localizedCountryName,
          localizedStateName,
          localizedCityName,
          localizedAddressName,
        ] = getLocalizedToponyms(contactAddress, lang);

        userContactAddresses.push({
          ...CONTACT_ADDRESS_INITIAL_VALUES,
          ...{
            name:
              contactAddress?.contact?.customValues?.contactCategory ===
              ContactCategory.Business
                ? contactAddress?.contact?.name
                : `${contactAddress.contact?.contactFirstName} ${contactAddress.contact?.contactLastName}`,
            contactName: `${contactAddress.contact?.contactFirstName} ${contactAddress.contact?.contactLastName}`,
            addressLine1: `${localizedAddressName}`,
            addressLine2: `${contactAddress.addressLine2 ?? ""}`,
            houseNumber: `${
              contactAddress.customValues?.toAddressHouseNumber ?? ""
            }`,
            city: localizedCityName,
            region: localizedStateName,
            postalCode: contactAddress.postalCode,
            country: localizedCountryName,
            phoneNumber: contactAddress?.contact?.phoneNumber,
          },
          ...(contactAddress.customValues?.is_pickup_location
            ? {
                pickupLocationFirstName:
                  contactAddress?.contact?.contactFirstName || "",
                pickupLocationLastName:
                  contactAddress?.contact?.contactLastName || "",
                pickupLocationPhoneNumber:
                  contactAddress?.contact?.phoneNumber || "",
                pickupLocationCountryCode: contactAddress?.countryCode || "",
                pickupLocationCountryName: localizedCountryName || "",
                pickupLocationContactAddressId:
                  contactAddress?.contactAddressId || null,
                pickupLocationPostOffices: true,
                pickupLocationParcelLockers: true,
                pickupLocationAddressLine: localizedAddressName || "",
                contactId: contactAddress?.contactId,
                contactAddressId: contactAddress?.contactAddressId,
                addressLine: localizedAddressName || "",
                isPickupLocation: true,
                pickupLocationContactCategory: contactAddress?.contact
                  ?.customValues?.contactCategory as ContactCategory,
                pickupLocationCompanyName: contactAddress?.contact?.name ?? "",
              }
            : {
                toAddressFirstName:
                  contactAddress?.contact?.contactFirstName || "",
                toAddressLastName:
                  contactAddress?.contact?.contactLastName || "",
                toAddressPhoneNumber:
                  contactAddress?.contact?.phoneNumber || "",
                toAddressEmail: contactAddress?.contact?.emailAddress || "",
                toAddressCountryCode: contactAddress?.countryCode || "",
                toAddressCountryName: localizedCountryName || "",
                toAddressRegionCode: contactAddress?.stateCode || "",
                toAddressRegionName: localizedStateName || "",
                toAddressDistrict:
                  contactAddress?.customValues?.toAddressDistrict || "",
                toAddressCity: contactAddress?.cityName || "",
                toAddressPostalCode: contactAddress?.postalCode || "",
                toAddressStreetName: contactAddress?.addressLine || "",
                toAddressHouseNumber:
                  contactAddress?.customValues?.toAddressHouseNumber || "",
                toAddressApartment: contactAddress?.addressLine2 || "",
                toAddressSetAsDefault: false,
                contactId: contactAddress?.contactId,
                contactAddressId: contactAddress?.contactAddressId,
                addressLine: contactAddress?.addressLine || "",
                isPickupLocation: false,
                toAddressContactCategory: contactAddress?.contact?.customValues
                  ?.contactCategory as ContactCategory,
                toAddressCompanyName: contactAddress?.contact?.name ?? "",
              }),
        });
      }
    }

    return userContactAddresses;
  };

  useEffect(() => {
    if (userState?.contactId) {
      GetContact(userState.contactId).then((contact: any) => {
        const responseDefaultContactAddressId =
          contact.data.customValues?.default_contact_address_ID;

        if (responseDefaultContactAddressId) {
          setDefaultContactAddressId(responseDefaultContactAddressId);
        }
      });
    }
    getUserContacts().then((contacts) => {
      setContactAddresses(mapContactsToFormValues(contacts));
    });
  }, [
    userState?.contactId,
    triggerContactsRefetch,
    triggerUpdateAddresses,
    lang,
  ]);

  return (
    <Grid container columns={{xs: 6, md: 12}}>
      <Grid lg={12} xl={editingWidth ? 9 : 12}>
        <UserAddressesForm
          defaultContactAddressId={defaultContactAddressId}
          setDefaultContactAddressId={setDefaultContactAddressId}
          contactAddresses={contactAddresses}
          onChangePageState={onChangePageState}
          triggerHandleBack={triggerHandleBack}
          updateAddresses={handleUpdateAddresses}
          setEditingWidth={setEditingWidth}
        />
      </Grid>
    </Grid>
  );
}
