import React, {useEffect, useMemo, useState} from "react";
import {Form, Formik, FormikProps} from "formik";
import {getDeliveryAddressValidationSchema} from "../../../Delivery/pages/validation";
import ToAddressComponent from "../../../Delivery/DeliveryAddress/components/toAddressComponent";
import {
  ContactCategory,
  DeliveryAddressCommandValues,
  DeliveryAddressFormFields,
  UserAddressesFormProps,
} from "../../../Delivery/DeliveryAddress/components/interfaces";
import Grid from "@mui/material/Unstable_Grid2";
import {useMediaQuery, useTheme} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {PageFragments} from "../pages";
import {LoadingButton} from "@mui/lab";
import {UserContactAddressInfo} from "./index";
import {useAppSelector} from "../../../store";
import {toast} from "react-hot-toast";
import {
  useContactAddressDeleteContactAddressMutation,
  useContactAddressUpdateContactAddressMutation,
} from "../../../features/contactAddress/contactAddress-api";
import {
  useContactCreateContactMutation,
  useContactUpdateContactMutation,
} from "../../../features/contact/contact-api";
import {toastError} from "../../../common/utils/toastMessages";
import AddressCard from "../../../common/components/addressCard";
import AddAddressCard from "../../../common/components/addAddressCard";
import DeleteContactAddressModal from "./DeleteContactAddressModal";
import ModalForm from "../../../common/Modal/components/modalForm";
import {
  CONTACT_ADDRESS_INITIAL_VALUES,
  getInitialValues,
  GetNewDefaultId,
} from "../../../Delivery/DeliveryAddress/components/helpers";
import DoubleCard from "../../../common/components/doubleCard";
import PickupLocationComponent from "../../../Delivery/DeliveryAddress/components/pickupLocationComponent";
import {getValidationSchemaRequiredFields} from "../../../common/hooks/useFormRequiredFields";
import {CountryFilter} from "../../../common/hooks/useGetCountries";
import {trimStringValues} from "../../../../utils/string.utils";

const deliveryAddressFormInitialValues = getInitialValues();
export default function UserAddressesForm({
  contactAddresses,
  onChangePageState = () => 0,
  triggerHandleBack = undefined,
  updateAddresses,
  setEditingWidth,
}: UserAddressesFormProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const {t} = useTranslation();
  const userState = useAppSelector((state) => state.userState);

  const [currentAddress, setCurrentAddress] =
    useState<UserContactAddressInfo | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [removeAddressModalOpen, setRemoveAddressModalOpen] =
    useState<boolean>(false);
  const [addressToRemove, setAddressToRemove] =
    useState<UserContactAddressInfo | null>(null);
  const [createContact] = useContactCreateContactMutation();
  const [updateContactAddress] =
    useContactAddressUpdateContactAddressMutation();
  const [deleteContactAddress] =
    useContactAddressDeleteContactAddressMutation();
  const [setDefaultContactAddress] = useContactUpdateContactMutation();

  const [validationSchema, setValidationSchema] = useState(
    getDeliveryAddressValidationSchema("toAddress", t),
  );

  useEffect(() => {
    if (currentAddress) {
      setEditingWidth(true);
    } else {
      setEditingWidth(false);
    }
  }, [currentAddress]);

  useEffect(() => {
    if (triggerHandleBack !== undefined) {
      setCurrentAddress(null);
    }
  }, [triggerHandleBack]);

  const handleAddAddress = () => {
    onChangePageState(PageFragments.AddingAddress);
    setCurrentAddress(CONTACT_ADDRESS_INITIAL_VALUES);
    window.scrollTo(0, 0);
  };

  const handleEditAddress = (contactAddress: UserContactAddressInfo) => {
    onChangePageState(
      contactAddress.isPickupLocation ||
        contactAddress.pickupLocationContactAddressId
        ? PageFragments.EditingPickupLocation
        : PageFragments.EditingAddress,
    );
    setCurrentAddress(contactAddress);
    window.scrollTo(0, 0);
  };

  const handleRemoveAddressConfirm = async () => {
    if (!addressToRemove) return;

    const contactAddress = addressToRemove;
    if (isSubmitting) return;

    setIsSubmitting(true);
    try {
      const deleteContactAddressResponse = await deleteContactAddress({
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        contactAddressId: contactAddress.contactAddressId as number,
        deleteContactAddressCommandValues: {
          contactId: contactAddress.contactId as number,
          customerId: userState.contactId as number,
          isDefault: false,
          newDefaultId: GetNewDefaultId(
            contactAddresses,
            contactAddress.contactAddressId,
          ),
        },
      });

      if ("error" in deleteContactAddressResponse) {
        handleSubmitError(deleteContactAddressResponse.error);
        return;
      }

      toast.success(t("toasts.addressWasDeleted"));
      updateAddresses?.();
    } catch (error) {
      handleSubmitError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRemoveAddress = (contactAddress: UserContactAddressInfo) => {
    setAddressToRemove(contactAddress);
    setRemoveAddressModalOpen(true);
  };

  const handleSubmitError = (error: any) => {
    toastError(error);
  };

  const handleCreateNewContact = (
    values: DeliveryAddressCommandValues,
  ): Promise<{data?: {contactId: number}; error?: any}> => {
    return createContact({
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      createContactCommand: {
        divisionId: process.env
          .REACT_APP_PORTAL_DIVISION_ID as unknown as number,
        values: values.pickupLocationContactAddressId
          ? {
              name:
                values?.pickupLocationContactCategory ===
                ContactCategory.Business
                  ? values.pickupLocationCompanyName
                  : `${values.pickupLocationFirstName} ${values.pickupLocationLastName}`,
              firstName: values.pickupLocationFirstName,
              lastName: values.pickupLocationLastName,
              phoneNumber: values.pickupLocationPhoneNumber,
              contactType: "Contact",
              parentContactId: userState.contactId,
              setAsDefault: false,
              customValues: {
                contactCategory: values.pickupLocationContactCategory,
              },
              shippingAddress: {
                contactAddressId: values.pickupLocationContactAddressId,
                customValues: values.pickupLocationContactAddressId
                  ? {
                      forwardingAgentAddressId:
                        values.pickupLocationContactAddressId.toString(),
                    }
                  : null,
              },
            }
          : {
              name:
                values?.toAddressContactCategory === ContactCategory.Business
                  ? values.toAddressCompanyName
                  : `${values.toAddressFirstName} ${values.toAddressLastName}`,
              firstName: values.toAddressFirstName,
              lastName: values.toAddressLastName,
              phoneNumber: values.toAddressPhoneNumber,
              emailAddress: values.toAddressEmail,
              contactType: "Contact",
              parentContactId: userState.contactId,
              setAsDefault:
                values.toAddressSetAsDefault || contactAddresses?.length === 0,
              customValues: {
                contactCategory: values.toAddressContactCategory,
              },
              shippingAddress: {
                countryCode: values.toAddressCountryCode || "",
                regionCode: values.toAddressRegionCode || "",
                cityName: values.toAddressCity || "",
                postalCode: values.toAddressPostalCode || "",
                streetName: values.toAddressStreetName || "",
                apartment: values.toAddressApartment || "",
                customValues: {
                  toAddressHouseNumber: values?.toAddressHouseNumber,
                  toAddressDistrict: values?.toAddressDistrict,
                },
              },
            },
      },
    }) as Promise<{data?: any; error?: any}>;
  };

  const handleUpdateContactAddress = (
    values: DeliveryAddressCommandValues,
  ): Promise<{data?: {contactId: number}; error?: any}> => {
    return updateContactAddress({
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      contactAddressId: currentAddress?.contactAddressId || 0,
      updateContactAddressCommandValues: {
        contactName:
          values.toAddressContactCategory === ContactCategory.Business
            ? values.toAddressCompanyName
            : `${values.toAddressFirstName} ${values.toAddressLastName}`,
        contactCategory: values.toAddressContactCategory,
        addressLine: values.toAddressStreetName || "",
        addressLine2: values.toAddressApartment || "",
        cityName: values.toAddressCity || "",
        countryCode: values.toAddressCountryCode || "",
        postalCode: values.toAddressPostalCode || "",
        stateCode: values.toAddressRegionCode || "",
        firstName: values.toAddressFirstName || "",
        lastName: values.toAddressLastName || "",
        phoneNumber: values.toAddressPhoneNumber || "",
        emailAddress: values.toAddressEmail || "",
        customValues: {
          toAddressHouseNumber: values?.toAddressHouseNumber,
          toAddressDistrict: values?.toAddressDistrict,
        },
      },
    }) as Promise<{data?: any; error?: any}>;
  };

  const handleSubmit = async (values: DeliveryAddressCommandValues) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    try {
      values = trimStringValues(values);

      const isCreatingNewAddress =
        !currentAddress?.contactId || !currentAddress?.contactAddressId;

      if (isCreatingNewAddress) {
        const createContactResponse = await handleCreateNewContact(values);
        if (createContactResponse?.error) {
          handleSubmitError(createContactResponse?.error);
          return;
        }
      } else {
        const updateContactAddressResponse = await handleUpdateContactAddress(
          values,
        );
        if (updateContactAddressResponse?.error) {
          handleSubmitError(updateContactAddressResponse?.error);
          return;
        }
      }

      toast.success(
        isCreatingNewAddress
          ? t("toasts.addressWasAdded")
          : t("toasts.addressWasUpdated"),
      );
      onChangePageState(PageFragments.SavedAddressesList);
      setCurrentAddress(null);
      window.scroll(0, 0);
    } catch (error) {
      handleSubmitError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const validationSchemaRequiredFields = useMemo(() => {
    return getValidationSchemaRequiredFields(validationSchema);
  }, [validationSchema]);

  const leftContent = (formikProps: any) => {
    return (
      <ToAddressComponent
        key={"deliveryAddress"}
        showMyAddresses={false}
        formikProps={formikProps as FormikProps<DeliveryAddressFormFields>}
        disabled={isSubmitting}
        showSaveAddressCheckbox={false}
        initialActiveCountry={currentAddress?.toAddressCountryCode ?? null}
        setValidationSchema={setValidationSchema}
        isDefaultAddress={false}
        phoneNumberDefaultCountryCode={"ua"}
        requiredFields={validationSchemaRequiredFields}
        countryFilter={CountryFilter.Delivery}
      />
    );
  };

  const rightContent = (formikProps: any) => {
    return (
      <PickupLocationComponent
        key={"deliveryAddress"}
        formikProps={formikProps as FormikProps<DeliveryAddressFormFields>}
        initialActiveCountry={currentAddress?.pickupLocationCountryCode ?? null}
        initialActiveMarkerId={
          currentAddress?.pickupLocationContactAddressId ?? null
        }
        setValidationSchema={setValidationSchema}
        phoneNumberDefaultCountryCode="ua"
        requiredFields={validationSchemaRequiredFields}
        countryFilter={CountryFilter.Delivery}
      />
    );
  };

  return currentAddress ? (
    <Grid container columns={{xs: 6, md: 12}}>
      <Grid xs={6} md={12}>
        <Formik
          initialValues={{
            ...deliveryAddressFormInitialValues,
            ...currentAddress,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form>
              <DoubleCard
                variant={isDesktop ? "normal" : "small"}
                leftLabel={t("delivery.address.addresses")}
                leftContent={leftContent(formikProps)}
                rightLabel={t("delivery.address.pickupLocations")}
                rightContent={rightContent(formikProps)}
                initialSelected={1}
                bgColorActive="white"
                bgColorInactive="#D8E6FB"
                lineColor="primary.main"
                disableRight={currentAddress.isPickupLocation === false}
                onClickLeft={() => {
                  setValidationSchema(
                    getDeliveryAddressValidationSchema("toAddress", t),
                  );
                  setEditingWidth(true);
                }}
                onClickRight={() => {
                  setValidationSchema(
                    getDeliveryAddressValidationSchema("pickupLocation", t),
                  );
                  setEditingWidth(true);
                }}
              />
              <Grid xs sx={{margin: "20px 0"}}>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={isSubmitting || !formikProps.isValid}
                  data-testid="btn-submit"
                  color="secondary"
                >
                  <Trans i18nKey={"save"}>Save</Trans>
                </LoadingButton>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  ) : (
    <Grid
      sx={{
        paddingX: 2,
        "& .MuiCard-root": {
          height: "100%",
        },
      }}
      spacing={isDesktop ? 5 : 2}
      justifyContent={isDesktop ? "flex-start" : "center"}
      container
      columns={{xs: 6, md: 12}}
    >
      <Grid xs={6} md={6} lg={4} height={isDesktop ? "initial" : "max-content"}>
        <AddAddressCard
          handleAddAddress={handleAddAddress}
          isDesktop={isDesktop}
        />
      </Grid>
      {contactAddresses?.map((contactAddress, index) => {
        return (
          <Grid
            xs={6}
            md={6}
            lg={4}
            key={index}
            display={
              isSubmitting &&
              contactAddress.contactAddressId ===
                addressToRemove?.contactAddressId
                ? "flex"
                : "block"
            }
            alignItems={"center"}
            justifyContent={"center"}
          >
            <AddressCard
              index={index}
              name={contactAddress.name}
              contactName={contactAddress.contactName}
              phoneNumber={contactAddress.phoneNumber}
              addressLine1={contactAddress.addressLine1}
              addressLine2={contactAddress.addressLine2}
              houseNumber={contactAddress.houseNumber}
              region={contactAddress.region}
              city={contactAddress.city}
              country={contactAddress.country}
              isDefault={false}
              onEditClick={() => handleEditAddress(contactAddress)}
              onRemoveClick={() => handleRemoveAddress(contactAddress)}
              isDesktop={isDesktop}
              isPickupLocation={contactAddress.isPickupLocation}
              isHoverDisabled={true}
              isLoading={
                isSubmitting &&
                contactAddress.contactAddressId ===
                  addressToRemove?.contactAddressId
              }
            />
          </Grid>
        );
      })}
      <ModalForm open={removeAddressModalOpen}>
        <DeleteContactAddressModal
          handleClose={() => setRemoveAddressModalOpen(false)}
          handleConfirm={() => {
            handleRemoveAddressConfirm();
            setRemoveAddressModalOpen(false);
          }}
        />
      </ModalForm>
    </Grid>
  );
}
