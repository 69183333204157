import {UserContactAddressInfo} from "../../../UserProfile/AccountSavedAddresses/components";
import {ContactCategory, DeliveryAddressFormFields} from "./interfaces";

export const GetNewDefaultId = (
  contactAddresses: UserContactAddressInfo[] | null,
  deletedContactAddressId: number | null | undefined,
): number | null => {
  if (!contactAddresses || !deletedContactAddressId) {
    return null;
  }
  const newDefaultAddress = contactAddresses.find(
    (address) => address.contactAddressId !== deletedContactAddressId,
  );
  return newDefaultAddress && newDefaultAddress.contactAddressId !== undefined
    ? newDefaultAddress.contactAddressId
    : null;
};
export const getInitialValues = (): DeliveryAddressFormFields => {
  return {
    toAddressFirstName: "",
    toAddressLastName: "",
    toAddressPhoneNumber: "",
    toAddressEmail: "",
    toAddressCountryCode: "",
    toAddressCountryName: "",
    toAddressRegionCode: "",
    toAddressRegionName: "",
    toAddressDistrict: "",
    toAddressCity: "",
    toAddressPostalCode: "",
    toAddressStreetName: "",
    toAddressHouseNumber: "",
    toAddressApartment: "",
    toAddressSetAsDefault: false,
    toAddressLatitude: null,
    toAddressLongitude: null,
    toAddressContactCategory: ContactCategory.Individual,
    toAddressCompanyName: "",
    pickupLocationFirstName: "",
    pickupLocationLastName: "",
    pickupLocationPhoneNumber: "",
    pickupLocationEmail: "",
    pickupLocationDistrict: "",
    pickupLocationCountryCode: "",
    pickupLocationCountryName: "",
    pickupLocationContactAddressId: null,
    pickupLocationPostOffices: true,
    pickupLocationParcelLockers: true,
    pickupLocationAddressLine: "",
    pickupLocationContactCategory: ContactCategory.Individual,
    pickupLocationCompanyName: "",
  };
};

export const CONTACT_ADDRESS_INITIAL_VALUES: UserContactAddressInfo = {
  toAddressFirstName: "",
  toAddressLastName: "",
  toAddressPhoneNumber: "",
  toAddressEmail: "",
  toAddressCountryCode: "",
  toAddressCountryName: "",
  toAddressRegionCode: "",
  toAddressRegionName: "",
  toAddressDistrict: "",
  toAddressCity: "",
  toAddressPostalCode: "",
  toAddressStreetName: "",
  toAddressHouseNumber: "",
  toAddressApartment: "",
  toAddressSetAsDefault: false,
  toAddressContactCategory: ContactCategory.Individual,
  toAddressCompanyName: "",
  pickupLocationFirstName: "",
  pickupLocationLastName: "",
  pickupLocationPhoneNumber: "",
  pickupLocationEmail: "",
  pickupLocationDistrict: "",
  pickupLocationCountryCode: "",
  pickupLocationCountryName: "",
  pickupLocationContactAddressId: null,
  pickupLocationPostOffices: true,
  pickupLocationParcelLockers: true,
  pickupLocationAddressLine: "",
  pickupLocationContactCategory: ContactCategory.Individual,
  pickupLocationCompanyName: "",
  contactId: null,
  contactAddressId: null,
  addressLine: "",
  isPickupLocation: null,
};
