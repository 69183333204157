import {RootState, useAppSelector} from "../../store";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../workflowTriggers/workflowExecution-api";
import {useCallback, useEffect} from "react";
import {toastError} from "../../common/utils/toastMessages";
import {SendVerifyEmailCommand} from "./verification-api";

export const useVerifyEmailCode = () => {
  const workflowId = useAppSelector(
    (state: RootState) =>
      state.organizationConfigState?.workflows?.verifyEmailCodeWorkflowId,
  );

  const [runWorkflow, {isLoading, isError, error, isSuccess}] =
    useWorkflowExecutionMutation();

  const verifyEmailCode = useCallback(
    async (data: SendVerifyEmailCommand) => {
      const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        workflowId: workflowId,
        values: {
          variables: {
            ...data,
          },
        },
      };

      try {
        return await runWorkflow(executeWorkflowApiArgs);
      } catch (err) {
        console.error(err);
      }
    },
    [runWorkflow, workflowId],
  );

  useEffect(() => {
    if (isError) {
      toastError(error);
    }
  }, [isLoading]);

  return {
    verifyEmailCode,
    isLoading,
    isError,
    error,
    isSuccess,
  };
};
