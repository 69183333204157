import React, {useEffect, useState} from "react";
import {Trans} from "react-i18next";
import {useDispatch} from "react-redux";
import {Box, Button, Card, CircularProgress} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {useAppSelector} from "../../../store";
import {ShippingAddressFragmentProps} from "./interfaces";
import {
  ContactAddressDto,
  useLazyContactAddressGetContactAddressQuery,
} from "../../../features/contactAddress/contactAddress-api";
import AddressCard from "../../../common/components/addressCard";
import {PageFragments} from "../../../UserProfile/AccountSavedAddresses/pages";
import {toastError} from "../../../common/utils/toastMessages";
import {Helmet} from "react-helmet";
import {getLangCodeFromI18n} from "../../../app/app.component";
import {getLocalizedToponyms} from "../../../ParcelShipments/AllParcelShipments/utils/parcelShipmentTable.utils";
import {
  AddressCardValues,
  ContactAddressValues,
  ContactCategory,
} from "../../DeliveryAddress/components/interfaces";
import ShippingAddressForm from "../shippingAddressFrom";
import {setContactAddress} from "../../../features/parcel/parcel-slice";

export const userContactAddressInfoInitialValues: ContactAddressValues = {
  toAddressFirstName: "",
  toAddressLastName: "",
  toAddressPhoneNumber: "",
  toAddressEmail: "",
  toAddressCountryCode: "",
  toAddressCountryName: "",
  toAddressRegionCode: "",
  toAddressRegionName: "",
  toAddressDistrict: "",
  toAddressCity: "",
  toAddressPostalCode: "",
  toAddressStreetName: "",
  toAddressHouseNumber: "",
  toAddressApartment: "",
  toAddressSetAsDefault: false,
  toAddressContactCategory: ContactCategory.Individual,
  toAddressCompanyName: "",
  contactId: null,
  contactAddressId: null,
};

export default function ShippingAddressFragment({
  handleNext,
  pageTitle,
  config,
  defaultCountry,
}: ShippingAddressFragmentProps) {
  const lang = getLangCodeFromI18n();
  const dispatch = useDispatch();
  const orderData = useAppSelector((state: any) => state.parcelState);

  const [editingWidth, setEditingWidth] = useState<boolean>(false);
  const [pageFragment, setPageFragment] = useState<PageFragments>(3);

  const [editingAddress, setEditingAddress] =
    useState<ContactAddressValues | null>(null);

  const [currentShippingAddressId, setCurrentShippingAddressId] =
    useState<number | null>(null);

  const [currentShippingAddress, setCurrentShippingAddress] =
    useState<
      | (ContactAddressValues &
          AddressCardValues & {
            contactCustomValues?: any;
            countryCustomValues?: any;
          })
      | null
      | undefined
    >();

  const [selectedAddressIsLoading, setSelectedAddressIsLoading] =
    useState<boolean>(true);

  const [getContactAddressQuery] =
    useLazyContactAddressGetContactAddressQuery();

  const getContactAddress = async (
    contactAddressId: number,
  ): Promise<ContactAddressDto | null> => {
    try {
      const response = await getContactAddressQuery({
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        contactAddressId: contactAddressId,
      });

      if (response?.error || !response?.data) {
        toastError(response?.error || "Failed to get selected contact address");
        return null;
      }

      return response.data;
    } catch (error) {
      toastError(error as Error);
      return null;
    }
  };

  const setCurrentAddressFormDto = (
    contactAddress?: ContactAddressDto | null,
  ) => {
    if (contactAddress) {
      const [
        localizedCountryName,
        localizedStateName,
        localizedCityName,
        localizedAddressName,
      ] = getLocalizedToponyms(contactAddress, lang);

      setCurrentShippingAddress({
        ...userContactAddressInfoInitialValues,
        ...{
          contactId: contactAddress.contact?.contactId,
          name: contactAddress.contact?.name,
          contactName: `${contactAddress.contact?.contactFirstName} ${contactAddress.contact?.contactLastName}`,
          addressLine1: localizedAddressName,
          addressLine2: contactAddress.addressLine2 ?? "",
          houseNumber: contactAddress.customValues?.toAddressHouseNumber ?? "",
          city: localizedCityName,
          district: contactAddress.customValues?.toAddressDistrict ?? null,
          region: localizedStateName,
          postalCode: contactAddress.postalCode,
          country: localizedCountryName,
          countryCustomValues: contactAddress?.country?.customValues,
          phoneNumber: contactAddress?.contact?.phoneNumber,
          contactCustomValues: contactAddress?.contact?.customValues,
        },
        ...{
          toAddressFirstName: contactAddress.contact?.contactFirstName ?? "",
          toAddressLastName: contactAddress.contact?.contactLastName ?? "",
          toAddressPhoneNumber: contactAddress.contact?.phoneNumber ?? "",
          toAddressEmail: contactAddress.contact?.emailAddress ?? "",
          toAddressCountryCode: contactAddress.countryCode ?? "",
          toAddressCountryName: localizedCountryName ?? "",
          toAddressRegionCode: contactAddress.stateCode ?? "",
          toAddressRegionName: localizedStateName ?? "",
          toAddressDistrict:
            contactAddress.customValues?.toAddressDistrict ?? "",
          toAddressCity: contactAddress.cityName ?? "",
          toAddressPostalCode: contactAddress.postalCode ?? "",
          toAddressStreetName: contactAddress.addressLine ?? "",
          toAddressLatitude: contactAddress.latitude ?? null,
          toAddressLongitude: contactAddress.longitude ?? null,
          toAddressHouseNumber:
            contactAddress.customValues?.toAddressHouseNumber ?? "",
          toAddressApartment:
            contactAddress.customValues?.toAddressApartment ?? "",
          toAddressCompanyName: contactAddress.contact?.name ?? "",
          toAddressContactCategory:
            contactAddress.contact?.customValues?.contactCategory ??
            ContactCategory.Individual,
          toAddressSetAsDefault: false,
          contactAddressId: contactAddress.contactAddressId ?? null,
          addressLine: contactAddress.addressLine ?? "",
          isPickupLocation: false,
        },
      });
    } else {
      setCurrentShippingAddress(contactAddress);
    }
  };

  useEffect(() => {
    if (orderData?.order?.contactAddressValues?.contactAddressId) {
      setSelectedAddressIsLoading(true);
      getContactAddress(
        orderData?.order?.contactAddressValues?.contactAddressId,
      )
        .then((contactAddress) => {
          if (contactAddress) {
            setCurrentAddressFormDto(contactAddress);
          } else {
            setCurrentShippingAddress(null);
          }
        })
        .finally(() => {
          setSelectedAddressIsLoading(false);
        });
    } else {
      setCurrentShippingAddress(null);
    }
    setSelectedAddressIsLoading(false);
  }, []);

  useEffect(() => {
    saveCurrentAddressToStorage();
  }, [currentShippingAddress]);

  const saveCurrentAddressToStorage = () => {
    if (currentShippingAddress) {
      const saveContactAddress = setContactAddress({
        contactValues: {
          contactId: currentShippingAddress?.contactId,
          name: currentShippingAddress?.toAddressCompanyName,
          firstName: currentShippingAddress?.toAddressFirstName,
          lastName: currentShippingAddress?.toAddressLastName,
          phoneNumber: currentShippingAddress?.toAddressPhoneNumber,
          emailAddress: currentShippingAddress?.toAddressEmail,
          customValues: currentShippingAddress?.contactCustomValues,
        },
        contactAddressValues: {
          contactAddressId: currentShippingAddress?.contactAddressId,
          countryCode: currentShippingAddress?.toAddressCountryCode,
          countryName: currentShippingAddress?.toAddressCountryName,
          regionCode: currentShippingAddress?.toAddressRegionCode,
          regionName: currentShippingAddress?.toAddressRegionName,
          district: currentShippingAddress?.toAddressDistrict,
          city: currentShippingAddress?.toAddressCity,
          postalCode: currentShippingAddress?.toAddressPostalCode,
          streetName: currentShippingAddress?.toAddressStreetName,
          houseNumber: currentShippingAddress?.toAddressHouseNumber,
          apartment: currentShippingAddress?.toAddressApartment,
          latitude: currentShippingAddress?.toAddressLatitude,
          longitude: currentShippingAddress?.toAddressLongitude,
        },
      });

      dispatch(saveContactAddress);
    }
  };

  const onClickContinue = () => {
    if (currentShippingAddress) {
      saveCurrentAddressToStorage();
      handleNext();
    }
  };

  const onEditClick = (currentDeliveryAddress: ContactAddressValues | null) => {
    setCurrentShippingAddress(null);
    setEditingAddress(currentDeliveryAddress);
    setPageFragment(4);
  };

  const onChooseAnotherClick = () => {
    setCurrentShippingAddress(null);
    setPageFragment(1);
  };

  const onChangePageState = (pageFragment: PageFragments) => {
    setPageFragment(pageFragment);
  };

  return selectedAddressIsLoading || currentShippingAddress === undefined ? (
    <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
      <CircularProgress />
    </Box>
  ) : currentShippingAddress ? (
    <Grid sx={{pt: 2}} container columns={{xs: 6, md: 12}}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Grid xs={6} md={4} lg={3}>
        <AddressCard
          name={currentShippingAddress?.name}
          contactName={currentShippingAddress?.contactName}
          phoneNumber={currentShippingAddress?.phoneNumber}
          addressLine1={currentShippingAddress?.addressLine1}
          addressLine2={currentShippingAddress?.addressLine2}
          houseNumber={currentShippingAddress?.houseNumber}
          region={currentShippingAddress?.region}
          city={currentShippingAddress?.city}
          country={currentShippingAddress?.country}
          district={currentShippingAddress?.district}
          postalCode={currentShippingAddress?.postalCode}
          onEditClick={() => onEditClick(currentShippingAddress || null)}
          onChooseAnotherClick={() => onChooseAnotherClick()}
          isDesktop={true}
          isDeliveryAddress={true}
          isHoverDisabled={true}
          isFromAddress={true}
        />
        <Grid sx={{py: {xs: 2, md: 5}}} md={7} xs={6}>
          <Button
            data-testid="btn-continue-delivery-address"
            fullWidth
            type="button"
            variant="contained"
            color="secondary"
            onClick={onClickContinue}
            sx={{
              p: 1,
              mb: 3,
            }}
          >
            <Trans i18nKey="btnContinue">Continue</Trans>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid sx={{paddingBottom: 7}} container columns={{xs: 6, md: 12}}>
      <Grid lg={12} xl={editingWidth ? 9 : 12}>
        <Card sx={{boxShadow: "none"}}>
          <ShippingAddressForm
            pageFragment={pageFragment}
            editingAddress={editingAddress}
            setCurrentShippingAddress={setCurrentShippingAddress}
            setCurrentShippingAddressId={setCurrentShippingAddressId}
            setEditingWidth={setEditingWidth}
            onChangePageState={onChangePageState}
            setEditingAddress={setEditingAddress}
            setCurrentAddressFormDto={setCurrentAddressFormDto}
            getContactAddress={getContactAddress}
            config={config}
            isFromAddress={true}
            defaultCountry={defaultCountry}
          />
        </Card>
      </Grid>
    </Grid>
  );
}
