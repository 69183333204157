import {ContactGQLDto} from "../../features/order/order-api";
import {getCustomValue} from "../../../utils/helper.utils";
import {ContactCategory} from "../../Delivery/DeliveryAddress/components/interfaces";
import {ContactDto} from "../../features/contactAddress/contactAddress-api";

export const getContactNameArray = (
  contact: ContactGQLDto | ContactDto | null | undefined,
): string[] | null => {
  if (!contact) return null;

  const contactName = [
    contact?.contactFirstName,
    contact?.contactLastName,
  ].join(" ");

  if (
    getCustomValue(contact?.customValues as any, "contactCategory") ===
    ContactCategory.Business
  ) {
    return [contact?.name ?? "", contactName]?.filter((m) => !!m);
  } else {
    return [contactName];
  }
};
