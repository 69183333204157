import {t} from "i18next";
import {basePortalApi as api} from "../basePortalApi";
import {UserDto} from "../user/user-api";
import {logout, setStorageToken} from "./auth-slice";
import {toastError} from "../../common/utils/toastMessages";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    tokenGet: build.mutation<TokenGetApiResponse, TokenGetApiArg>({
      query: (queryArg) => ({
        url: `/api/Token`,
        method: "POST",
        body: queryArg.getTokenCommand,
      }),
      async onQueryStarted(args, {dispatch, queryFulfilled}) {
        try {
          dispatch(logout());
          const {data} = await queryFulfilled;
          dispatch(setStorageToken(data));
        } catch (error: any) {
          dispatch(logout());
          toastError(t("toasts.loginInvalid"));
        }
      },
    }),
    tokenRefresh: build.mutation<TokenRefreshApiResponse, TokenRefreshApiArg>({
      query: (queryArg) => ({
        url: `/api/Token/refresh`,
        method: "POST",
        body: queryArg.refreshTokenCommand,
      }),
      async onQueryStarted(args, {dispatch, queryFulfilled}) {
        try {
          const {data} = await queryFulfilled;
          dispatch(setStorageToken(data));
        } catch (error) {
          toastError(error);
        }
      },
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as portalApi};
export type TokenGetApiResponse = /** status 200  */ TokenInfo;
export type TokenGetApiArg = {
  getTokenCommand: GetTokenCommand;
};
export type TokenRefreshApiResponse = /** status 200  */ TokenInfo;
export type TokenRefreshApiArg = {
  refreshTokenCommand: RefreshTokenCommand;
};
export type TokenInfo = {
  access_token?: string;
  expires_in?: number;
  refresh_token?: string | null;
  scope?: string | null;
  token_type?: string | null;
  userInfo?: UserDto;
};
export type GetTokenCommand = {
  email?: string | null;
  password?: string | null;
  grantType?: string;
  userType?: string;
  code?: string;
  redirectUri?: string;
  organizationId?: number;
  divisionId?: number;
  rememberMe?: boolean;
};
export type RefreshTokenCommand = {
  refreshToken?: string | null;
};
export const {useTokenGetMutation, useTokenRefreshMutation} = injectedRtkApi;
