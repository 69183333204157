import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import ContentLayout from "../../common/ContentLayout/components/contentLayout";
import MainLayout from "../../common/MainLayout/components/mainLayout";
import AdditionalServiceFragment from "../AdditionalServices/components/additionalServiceFragment";
import DeliveryMethodFragment from "../DeliveryMethod/components/deliveryMethodFragment";
import {Typography, useMediaQuery, useTheme} from "@mui/material";
import StatusBar from "../components/statusBar";
import ConfirmationFragment from "../Confirmation/components/confirmationFragment";
import CustomsDeclarationFragment from "../Declaration/components";
import DeliveryAddressFragment from "../DeliveryAddress/components/deliveryAddressFragment";
import PaymentMethodFragment from "../PaymentMethod/components/paymentMethodFragment";
import {
  loadStateFromDraft,
  OrderStepData,
  setStepState,
} from "../../features/order/parcelShipment-slice";
import {useDispatch} from "react-redux";
import {ContactPaymentMethodDto} from "../../features/paymentMethod/paymentMethod-api";
import SelectPurchasesFragment from "../SelectPurchases/components/selectPurchasesFragment";
import {useOrganizationConfig} from "../../common/OrganizationConfig/useOrganizationConfig";
import {PortalOrganizationConfigDto} from "../../features/organizationConfig/organizationConfig-api";
import {useContinueVerificationModal} from "../hooks/useContinueVerificationModal";
import {checkObjectChangeByPath} from "../../common/utils/checkObjectChangeByPath";
import {useDeliveryHelper} from "../hooks/useDeliveryHelper";
import {LoadingButton} from "@mui/lab";
import Grid from "@mui/material/Unstable_Grid2";
import {toastError} from "../../common/utils/toastMessages";
import toast from "react-hot-toast";
import i18next from "i18next";

type DeliveryStep = {
  key: string;
  component: JSX.Element;
  title: string;
  statusBarTitle: string;
  dependencies?: string[];
  isDisabled?: boolean;
};

export type DeliveryState = {
  orderData?: OrderStepData;
};

const Delivery = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const lang = i18next.language;

  const {moduleConfig} =
    useOrganizationConfig() || ({} as PortalOrganizationConfigDto);
  const deliveryConfig = moduleConfig?.customValues?.delivery;

  const {render: renderContinueVerificationModal} =
    useContinueVerificationModal();

  const {
    orderIds,
    stepKey,
    orderIdsParam,
    ordersData,
    currentOrderData,
    isConsolidated,
    completedSteps,
    getDraft,
    saveDraft,
    deleteDraft,
    draftInfo,
  } = useDeliveryHelper();

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<ContactPaymentMethodDto | null>(
      currentOrderData?.order?.contactPaymentMethod ?? null,
    );

  const navigateToStep = (step: string, orderIds: number[]) => {
    navigate(getLink(step, orderIds));
  };

  const handlePrev = () => {
    const previousStepIndex = currentStepIndex - 1;
    if (previousStepIndex >= 0) {
      navigateToStep(steps?.[previousStepIndex]?.key, orderIds);
      window.scrollTo(0, 0);
    } else {
      navigateToStep("selectRequestType", [orderIds?.[0] ?? 0]);
    }
  };

  const getLink = (step: string, orderIds: number[]) => {
    return `/${lang}/delivery/${orderIds?.join("&")}/${step ?? ""}`;
  };

  const handleNext = () => {
    if (completedSteps) {
      dispatch(
        setStepState({
          orderIds: orderIds,
          step: stepKey,
          isCompleted: true,
        }),
      );
      const newCompletedSteps = {
        ...completedSteps,
        [stepKey]: true,
      };
      const firstNotCompletedStep = getFirstNotCompletedStep(newCompletedSteps);
      navigateToStep(firstNotCompletedStep, orderIds);
    }
    window.scrollTo(0, 0);
  };

  const getLinkByStepKey = (stepKey: string) => {
    if (steps?.some((step) => step?.key == stepKey)) {
      return `../delivery/${orderIdsParam}/${stepKey}`;
    }
    return null;
  };

  const steps: DeliveryStep[] = useMemo(() => {
    return [
      {
        key: "selectPurchases",
        component: (
          <SelectPurchasesFragment
            handleNext={handleNext}
            pageTitle={"Ship Request - Select Purchases"}
          />
        ),
        title: t("delivery.statusBar.selectPurchasesTitle"),
        statusBarTitle: t("delivery.statusBar.selectPurchases"),
        dependencies: ["orderData"],
        isDisabled: !isConsolidated,
      },
      {
        key: "address",
        component: (
          <DeliveryAddressFragment
            handleNext={handleNext}
            pageTitle={"Ship Request - Delivery Address"}
          />
        ),
        title: t("delivery.statusBar.deliveryAddress"),
        statusBarTitle: t("delivery.statusBar.deliveryAddress"),
        dependencies: ["orderData"],
      },
      {
        key: "customs",
        component: (
          <CustomsDeclarationFragment
            handleNext={handleNext}
            pageTitle="Ship Request - Customs Declaration"
            config={deliveryConfig}
          />
        ),
        title: t("delivery.declaration.fillOutCustomsDeclarations"),
        statusBarTitle: t("delivery.statusBar.customsDeclaration"),
        dependencies: [
          "orderData.order.consigneeValues",
          "orderData.order.consigneeAddressValues",
        ],
      },
      {
        key: "method",
        component: (
          <DeliveryMethodFragment
            handleNext={handleNext}
            pageTitle="Ship Request - Delivery Methods"
            config={deliveryConfig}
          />
        ),
        title: t("delivery.declaration.deliveryMethod"),
        statusBarTitle: t("delivery.statusBar.deliveryMethod"),
        dependencies: [
          "orderData.order.consigneeValues",
          "orderData.order.consigneeAddressValues",
          "orderData.order.containerCommodities[0].customValues.dangerousItems",
        ],
      },
      {
        key: "additional",
        component: (
          <AdditionalServiceFragment
            handleNext={handleNext}
            pageTitle="Ship Request - Additional Services"
            config={deliveryConfig}
          />
        ),
        title: t("delivery.statusBar.additionalServices"),
        statusBarTitle: t("delivery.statusBar.additionalServices"),
        dependencies: ["orderData.order.deliveryMethod.rateId"],
      },
      {
        key: "payment",
        component: (
          <PaymentMethodFragment
            handleNext={handleNext}
            withInfoBox={true}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            selectedPaymentMethod={selectedPaymentMethod}
            pageTitle="Ship Request - Payment Methods"
          />
        ),
        title: t("delivery.statusBar.paymentMethod"),
        statusBarTitle: t("delivery.statusBar.paymentMethod"),
      },
      {
        key: "confirmation",
        component: (
          <ConfirmationFragment
            switchFragment={(stepKey: string) => {
              const link = getLinkByStepKey(stepKey);
              if (link) navigate(link);
              return;
            }}
            isConsolidated={isConsolidated}
            config={deliveryConfig}
          />
        ),
        title: t("delivery.statusBar.confirmation"),
        statusBarTitle: t("delivery.statusBar.confirmation"),
        dependencies: ["orderData"],
      },
    ].filter((x) => x.isDisabled !== true);
  }, [
    isConsolidated,
    handleNext,
    deliveryConfig,
    navigateToStep,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    t,
  ]);

  const [previousState, setPreviousState] = useState<DeliveryState>();
  const currentState: DeliveryState = useMemo(() => {
    return {
      orderData: currentOrderData,
    };
  }, [ordersData]);

  const getFirstNotCompletedStep = (completedSteps: {
    [step: string]: boolean;
  }) => {
    const stepKeys = steps.map((x) => x.key);
    for (const stepKey of stepKeys) {
      if (!completedSteps[stepKey]) {
        return stepKey;
      }
    }
    return stepKeys?.[0] ?? "";
  };

  const currentStepIndex = useMemo(() => {
    const index = steps.findIndex((step) => step.key === stepKey);
    if (index < 0) {
      return 0;
    }
    return index;
  }, [steps, stepKey]);

  const checkStepsResetConditions = useCallback(
    (previousState: DeliveryState, currentState: DeliveryState) => {
      if (completedSteps) {
        Object.keys(completedSteps).forEach((stepKey) => {
          const stepIndex = steps?.findIndex((x) => x.key === stepKey);
          if (stepIndex < 0) return;
          const step = steps[stepIndex];
          if (
            stepKey &&
            completedSteps[stepKey] === true &&
            stepIndex > currentStepIndex &&
            step?.dependencies
          ) {
            const stepDependencies = step.dependencies;
            stepDependencies?.forEach((stepDependencyPath) => {
              const isStepDependencyChanged = checkObjectChangeByPath(
                previousState,
                currentState,
                stepDependencyPath,
              );
              if (isStepDependencyChanged) {
                dispatch(
                  setStepState({
                    orderIds: orderIds,
                    step: stepKey,
                    isCompleted: false,
                  }),
                );
                return;
              }
            });
          }
        });
      }
    },
    [steps, completedSteps, currentStepIndex],
  );

  const [isLoadingDeleteDraft, setIsLoadingDeleteDraft] = useState(false);

  const handleDeleteDraft = useCallback(async () => {
    setIsLoadingDeleteDraft(true);
    try {
      if (deleteDraft) await deleteDraft(orderIds);
      navigate("../purchases");
      toast.success(t("delivery.toastMessages.shipRequestCancelled"));
    } catch (error) {
      toastError(error);
    } finally {
      setIsLoadingDeleteDraft(false);
    }
  }, [deleteDraft, setIsLoadingDeleteDraft, orderIds]);

  const draftInfoComponent = useMemo(() => {
    if (!draftInfo) return null;
    const draftNumber = draftInfo?.draftOrderNumber;

    return (
      <Grid
        display={"flex"}
        gap={2}
        alignItems={"center"}
        flexWrap={"wrap"}
        justifyContent={"end"}
      >
        <Typography variant={"body4"}>
          <Trans i18nKey={"delivery.draftInfo.unfinishedShipRequest"}>
            Unfinished Ship Request #{{draftNumber}}
          </Trans>
        </Typography>
        <LoadingButton
          size={"small"}
          variant={"outlined"}
          color={"error"}
          sx={{height: "40px", textTransform: "none"}}
          loading={isLoadingDeleteDraft}
          onClick={handleDeleteDraft}
        >
          <Trans i18nKey={"delivery.draftInfo.cancelButton"}>
            Cancel Ship Request
          </Trans>
        </LoadingButton>
      </Grid>
    );
  }, [draftInfo, isLoadingDeleteDraft, handleDeleteDraft]);

  useEffect(() => {
    if (previousState) checkStepsResetConditions(previousState, currentState);
    setPreviousState(currentState);
  }, [currentState]);

  useEffect(() => {
    if (saveDraft) saveDraft(currentOrderData);
  }, [completedSteps]);

  useEffect(() => {
    if (!stepKey && completedSteps) {
      const firstNotCompletedStep = getFirstNotCompletedStep(completedSteps);
      navigateToStep(firstNotCompletedStep, orderIds);
      window.scrollTo(0, 0);
    }
  }, [stepKey, completedSteps]);

  useEffect(() => {
    if (getDraft && orderIds && !currentOrderData?.loadedDraft)
      getDraft({orderIds})?.then((loadedDraft) => {
        if (loadedDraft)
          dispatch(loadStateFromDraft({orderIds, draft: loadedDraft}));
      });
  }, [getDraft, orderIds, currentOrderData]);

  useEffect(() => {
    if (!currentOrderData && orderIds && steps) {
      navigateToStep("selectRequestType", [orderIds?.[0] ?? 0]);
    }
  }, [currentOrderData, orderIds, dispatch, steps]);

  return (
    <>
      <MainLayout
        handlePrevStep={handlePrev}
        statusBar={
          <Grid>
            <StatusBar
              variant={isDesktop ? "linear" : "radial"}
              step={currentStepIndex}
              setStep={(step: string) => navigateToStep(step, orderIds)}
              steps={steps}
            />
          </Grid>
        }
        pageTitle="Ship Request"
        draftInfo={draftInfoComponent}
      >
        <ContentLayout
          content={steps[currentStepIndex]?.component}
          title={
            (
              <Trans i18nKey={steps[currentStepIndex]?.title} />
            ) as React.SetStateAction<any>
          }
          withoutTitle={
            currentStepIndex ===
            steps?.findIndex((step) => step.key === "additional")
          }
          withoutMobileTitle={true}
          handlePrev={handlePrev}
          draftInfo={draftInfoComponent}
        />
      </MainLayout>
      {renderContinueVerificationModal()}
    </>
  );
};

export default Delivery;
