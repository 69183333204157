import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IDeliveryMethod} from "../../Delivery/DeliveryMethod/components/interfaces";
import {
  CreateChargeCommand,
  CreateConsigneeAddressCommandValues,
  CreateConsigneeCommandValues,
  OrderTypes,
} from "../order/order-api";
import {OrderSlice} from "../order/parcelShipment-slice";
import {PaymentCardFormValues} from "../../Delivery/PayAndPrintLabel/components/paymentCardForm";
import {AddressFormValues} from "../../Delivery/PayAndPrintLabel/components/addressForm";

type ParcelSliceState = {
  order: OrderSlice | null;
  additionalServices: any;
  paymentCardValues: any;
  billingAddressValues: any;
  pickup: any;
  workflowResult: any;
  completedSteps?: {
    [stepKey: string]: boolean;
  };
};

const initialOrder: OrderSlice = {
  orderType: OrderTypes?.parcelShipment,
  consigneeValues: {},
  consigneeAddressValues: {},
  commodities: [],
  container: {},
  charges: [],
  chargesTotalPrice: 0,
  contactValues: {},
  contactAddressValues: {},
  customValues: {
    autoPay: false,
  },
};

const initialState: ParcelSliceState = {
  order: initialOrder,
  additionalServices: {},
  pickup: null,
  paymentCardValues: null,
  billingAddressValues: null,
  workflowResult: null,
  completedSteps: {},
};

export const parcelSlice = createSlice({
  initialState,
  name: "parcelSlice",
  reducers: {
    initOrder: (state) => {
      state.order = initialOrder;
    },
    resetOrder: (state) => {
      state.order = initialOrder;
      state.paymentCardValues = null;
      state.billingAddressValues = null;
      state.workflowResult = null;
      state.pickup = null;
      state.additionalServices = {};
      state.completedSteps = {};
    },
    setStepState: (
      state,
      action: PayloadAction<{step: string; isCompleted: boolean}>,
    ) => {
      state.completedSteps = {
        ...state.completedSteps,
        [action.payload.step]: action.payload.isCompleted,
      };
    },
    setContactAddress: (
      state,
      action: PayloadAction<{
        contactValues: CreateConsigneeCommandValues;
        contactAddressValues: CreateConsigneeAddressCommandValues;
      }>,
    ) => {
      const {contactValues, contactAddressValues} = action.payload;
      state.order = {
        ...state.order,
        contactValues: contactValues,
        contactAddressValues: contactAddressValues,
      };
    },
    setDeliveryAddress: (
      state,
      action: PayloadAction<{
        consignee: CreateConsigneeCommandValues;
        consigneeAddress: CreateConsigneeAddressCommandValues & {
          addressLine?: string | null;
          isPickupLocation?: boolean | null;
        };
      }>,
    ) => {
      const {consigneeAddress, consignee} = action.payload;
      state.order = {
        ...state.order,
        isPickupLocation: consigneeAddress.isPickupLocation || false,
        consigneeValues: consignee,
        consigneeAddressValues: consigneeAddress,
      };
    },
    setCommodity: (
      state,
      action: PayloadAction<{container: any; commodities: any[]}>,
    ) => {
      const {container, commodities} = action.payload;
      state.order = {
        ...state.order,
        container,
        commodities,
      };
    },
    setDeliveryMethod: (
      state,
      action: PayloadAction<{
        deliveryMethod: IDeliveryMethod;
      }>,
    ) => {
      const {deliveryMethod} = action.payload;
      state.order = {
        ...state.order,
        deliveryMethod,
      };
    },
    setDeliveryToTrtMethod: (
      state,
      action: PayloadAction<{
        deliveryMethod: IDeliveryMethod;
      }>,
    ) => {
      const {deliveryMethod} = action.payload;
      state.order = {
        ...state.order,
        deliveryToTrtMethod: deliveryMethod,
      };
    },
    setInsurance: (
      state,
      action: PayloadAction<{
        insurance: CreateChargeCommand;
      }>,
    ) => {
      state.order = {
        ...state.order,
        charges: [...(state.order?.charges || []), action.payload.insurance],
      };
    },
    setCharges: (
      state,
      action: PayloadAction<{
        charges: CreateChargeCommand[];
      }>,
    ) => {
      const {charges} = action.payload;
      if (state.order) {
        state.order.charges = charges;
        state.order.chargesTotalPrice = charges.reduce(
          (acc, charge) => acc + (charge.values?.totalAmount || 0),
          0,
        );
      }
    },
    setPaymentCardValues: (
      state,
      action: PayloadAction<{
        paymentCardValues: PaymentCardFormValues;
      }>,
    ) => {
      const {paymentCardValues} = action.payload;
      state.paymentCardValues = paymentCardValues;
    },
    setBillingAddressValues: (
      state,
      action: PayloadAction<{
        billingAddressValues: AddressFormValues;
      }>,
    ) => {
      const {billingAddressValues} = action.payload;
      state.billingAddressValues = billingAddressValues;
    },
    setWorkflowResult: (
      state,
      action: PayloadAction<{
        workflowResult: any;
      }>,
    ) => {
      const {workflowResult} = action.payload;
      state.workflowResult = workflowResult;
      console.log("workflowResult", workflowResult, state);
    },
    setAdditionalServices: (
      state,
      action: PayloadAction<{
        additionalServices: any;
      }>,
    ) => {
      const {additionalServices} = action.payload;
      state.additionalServices = additionalServices;
    },
    setOrderCustomValues: (
      state,
      action: PayloadAction<{
        values: any;
      }>,
    ) => {
      const {values} = action.payload;
      state.order = {
        ...state.order,
        customValues: {...state.order?.customValues, ...values},
      };
    },
    setPickup: (
      state,
      action: PayloadAction<{
        values: any;
      }>,
    ) => {
      const {values} = action.payload;
      state.pickup = {
        ...values,
      };
    },
  },
});

export default parcelSlice.reducer;

export const {
  initOrder,
  resetOrder,
  setContactAddress,
  setDeliveryAddress,
  setDeliveryMethod,
  setDeliveryToTrtMethod,
  setInsurance,
  setCommodity,
  setStepState,
  setCharges,
  setPaymentCardValues,
  setBillingAddressValues,
  setWorkflowResult,
  setAdditionalServices,
  setOrderCustomValues,
  setPickup,
} = parcelSlice.actions;
