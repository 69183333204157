import {t} from "i18next";
import {setStorageUserInfo} from "../user/user-slice";
import {
  setIsVerified,
  VerifiedValues,
} from "../verification/verification-slice";
import {basePortalApi as api} from "../basePortalApi";
import {toastError} from "../../common/utils/toastMessages";
import {logout} from "../auth/auth-slice";
import {logout as userLogout} from "../user/user-slice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    userSendResetPasswordEmail: build.mutation<
      UserSendResetPasswordEmailApiResponse,
      UserSendResetPasswordEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/sendResetPasswordEmail`,
        method: "POST",
        body: queryArg.sendResetPasswordLinkCommand,
      }),
    }),
    userResetPassword: build.mutation<
      UserResetPasswordApiResponse,
      UserResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/resetPassword`,
        method: "POST",
        body: queryArg.resetPasswordCommand,
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error) {
          toastError(error);
        }
      },
    }),
    userVerifyResetPasswordCode: build.mutation<
      UserVerifyResetPasswordCodeApiResponse,
      UserVerifyResetPasswordCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/verifyResetPasswordCode`,
        method: "POST",
        body: queryArg.verifyUserCodeCommand,
      }),
    }),
    userGetInfo: build.query<UserGetInfoApiResponse, UserGetInfoApiArg>({
      query: () => ({url: `/api/User`}),
      async onQueryStarted(args, {dispatch, queryFulfilled}) {
        try {
          const {data} = await queryFulfilled;
          if (data && data.contactId) {
            dispatch(setStorageUserInfo(data));
            const verifiedValues: VerifiedValues = {
              isAddressVerified: data.contactAddressConfirmed ?? false,
              isEmailVerified: data.emailConfirmed ?? false,
              isPaymentVerified: data.paymentMethodConfirmed ?? false,
              isPhoneVerified: data.phoneNumberConfirmed ?? false,
              isAcknowledgementVerified: data.acknowledgementConfirmed ?? false,
            };
            dispatch(setIsVerified(verifiedValues));
          } else {
            dispatch(userLogout());
            dispatch(logout());
            toastError(t("toasts.tmsUserDetected"));
          }
        } catch (error) {
          toastError(error, t("toasts.userInfoError"));
        }
      },
    }),
    userUpdateInfo: build.mutation<
      UserUpdateInfoApiResponse,
      UserUpdateInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User`,
        method: "PUT",
        body: queryArg.updateUserInfoCommand,
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error) {
          toastError(error);
        }
      },
    }),
    userRegistrationRegister: build.mutation<
      UserRegistrationRegisterApiResponse,
      UserRegistrationRegisterApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/divisions/${queryArg.divisionId}/userRegistration`,
        method: "POST",
        body: queryArg.createUserCommandValues,
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error) {
          if (
            (error as any)?.error?.data?.detail
              ?.toLowerCase()
              ?.includes("is already taken") ||
            (error as any)?.error?.data?.detail
              ?.toLowerCase()
              ?.includes("is already reserved")
          ) {
            toastError(t("toasts.emailIsAlreadyTaken"));
          } else {
            toastError(error);
          }
        }
      },
    }),
  }),
  overrideExisting: false,
});

export {injectedRtkApi as portalApi};
export type UserSendResetPasswordEmailApiResponse = unknown;
export type UserSendResetPasswordEmailApiArg = {
  sendResetPasswordLinkCommand: SendResetPasswordLinkCommand;
};
export type UserResetPasswordApiResponse = unknown;
export type UserResetPasswordApiArg = {
  resetPasswordCommand: ResetPasswordCommand;
};
export type UserVerifyResetPasswordCodeApiResponse = /** status 200  */ UserDto;
export type UserVerifyResetPasswordCodeApiArg = {
  verifyUserCodeCommand: VerifyUserCodeCommand;
};
export type UserGetInfoApiResponse = /** status 200  */ UserDto;
export type UserGetInfoApiArg = void;
export type UserUpdateInfoApiResponse = /** status 204  */ undefined;
export type UserUpdateInfoApiArg = {
  updateUserInfoCommand: UpdateUserInfoCommand;
};
export type UserRegistrationRegisterApiResponse =
  /** status 200  */ UserRegistrationResponseDto;
export type UserRegistrationRegisterApiArg = {
  organizationId: number;
  divisionId: number;
  createUserCommandValues: CreateUserCommandValues;
};
export type SendResetPasswordLinkCommand = {
  username?: string | null;
  isPortal?: boolean;
};
export type ResetPasswordCommand = {
  userId?: string | null;
  password?: string | null;
  code?: string | null;
};
export type VerifyUserCodeCommand = {
  userId?: string | null;
  code?: string | null;
};
export type LinkDto = {
  href?: string | null;
  rel?: string | null;
  type?: string | null;
  templated?: boolean | null;
};
export type UserDto = {
  userId?: string | null;
  contactId?: number | null;
  email?: string | null;
  accountNumber?: string | null;
  phoneNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  userName?: string | null;
  emailConfirmed?: boolean | null;
  phoneNumberConfirmed?: boolean | null;
  contactAddressConfirmed?: boolean | null;
  paymentMethodConfirmed?: boolean | null;
  acknowledgementConfirmed?: boolean | null;
  links?: LinkDto[] | null;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any | null;
};
export type UpdateUserInfoCommand = {
  organizationId?: number | null;
  contactId?: number | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
};
export type AddressType = "Billing" | "Shipping" | "Other";
export type ContactAddressDto = {
  contactAddressId?: number;
  addressLine?: string | null;
  addressLine2?: string | null;
  addressType?: AddressType;
  cityName?: string | null;
  contactId?: number;
  countryCode?: string | null;
  countryName?: string | null;
  created?: string;
  createdBy?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  organizationId?: number;
  postalCode?: string | null;
  stateCode?: string | null;
  stateName?: string | null;
  links?: LinkDto[] | null;
};
export type PaymentTermDto = {
  paymentTermId?: number;
  description?: string | null;
  discountPaidWithinDays?: number | null;
  discountPercentage?: number | null;
  isInactive?: boolean;
  netDueDays?: number;
  organizationId?: number;
  links?: LinkDto[] | null;
};
export type ContactType =
  | "Customer"
  | "Carrier"
  | "Vendor"
  | "Contact"
  | "Driver"
  | "Employee"
  | "SalesPerson"
  | "ForwardingAgent"
  | "FactoringCompany"
  | "Lead";
export type IdNumberType = "EIN" | "DUNS" | "ForeignEntityId" | "Other";
export type VisibleTransactions =
  | "AllTransactions"
  | "DivisionOnly"
  | "DivisionAndDivisionless";
export type ApplicationUserRoleDto = {
  id?: string | null;
  name?: string | null;
  normalizedName?: string | null;
};
export type UserGroupDto = {
  userGroupId?: number;
  created?: string;
  createdBy?: string | null;
  description?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  name?: string | null;
  organizationId?: number;
  userRoles?: ApplicationUserRoleDto[] | null;
  links?: LinkDto[] | null;
};
export type UserEmployeeDto = {
  userEmployeeId?: number;
  created?: string;
  createdBy?: string | null;
  employeeContactId?: number;
  lastModified?: string;
  lastModifiedBy?: string | null;
  organizationId?: number;
  userId?: string | null;
  visibleTransactions?: VisibleTransactions;
  employeeContactFirstName?: string | null;
  employeeContactLastName?: string | null;
  employeeName?: string | null;
  userFirstName?: string | null;
  userLastName?: string | null;
  userNormalizedUserName?: string | null;
  userUserName?: string | null;
  employeeDivisionId?: number;
  userGroups?: UserGroupDto[] | null;
  links?: LinkDto[] | null;
};
export type PaidAs = "Prepaid" | "Collect";
export type CarrierEquipmentDto = {
  carrierId?: number;
  equipmentTypeId?: number;
  quantity?: number;
};
export type CreateContactDto = {
  contactAddresses?: ContactAddressDto[] | null;
  paymentTerm?: PaymentTermDto | null;
  contactId?: number;
  accountNumber?: string | null;
  contactFirstName?: string | null;
  contactLastName?: string | null;
  contactType?: ContactType;
  created?: string;
  createdBy?: string | null;
  createdByUserName?: string | null;
  divisionId?: number;
  divisionName?: string | null;
  emailAddress?: string | null;
  faxNumber?: string | null;
  idNumber?: string | null;
  idNumberType?: IdNumberType;
  lastModified?: string;
  lastModifiedBy?: string | null;
  lastModifiedByUserName?: string | null;
  mobilePhoneNumber?: string | null;
  name?: string | null;
  organizationId?: number;
  parentContactId?: number | null;
  phoneNumber?: string | null;
  website?: string | null;
  paymentTermId?: number | null;
  paymentTermDescription?: string | null;
  userEmployee?: UserEmployeeDto | null;
  creditLimit?: number | null;
  paidAs?: PaidAs;
  isACorporation?: boolean | null;
  carrierEquipments?: CarrierEquipmentDto[] | null;
  customValues?: {
    [key: string]: string;
  } | null;
  links?: LinkDto[] | null;
};
export type ValidationProblemDetailsResponse = {
  errors?: {
    [key: string]: string[];
  } | null;
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  additionalProperties?: {
    [key: string]: any;
  } | null;
  [key: string]: any | null;
};
export type CreateUserCommandValues = {
  password?: string | null;
  email?: string | null;
  code?: string;
  grantType?: string;
  redirectUri?: string;
  termsCheck?: boolean;
  promoCode?: string | null;
  preferredLanguage?: string;
};
export type UserRegistrationResponseDto = {
  user: CreateContactDto;
  promoCodeError?: string | undefined;
};
export const {
  useUserSendResetPasswordEmailMutation,
  useUserResetPasswordMutation,
  useUserVerifyResetPasswordCodeMutation,
  useUserGetInfoQuery,
  useUserUpdateInfoMutation,
  useUserRegistrationRegisterMutation,
  useLazyUserGetInfoQuery,
} = injectedRtkApi;
