export const trimStringValues = (valuesObj: any) => {
  if (!valuesObj) return valuesObj;

  const clonedObj = structuredClone(valuesObj);

  Object.keys(clonedObj).forEach((k) => {
    if (typeof clonedObj[k] === "string") {
      clonedObj[k] = clonedObj[k].trim();
    } else {
      clonedObj[k] = trimStringValues(clonedObj[k]);
    }
  });

  return clonedObj;
};
