import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Field, FormikProps} from "formik";
import {CheckboxWithLabel, RadioGroup, TextField} from "formik-mui";
import i18next from "i18next";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  CircularProgress,
  FilterOptionsState,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import AddressFormatter from "@shopify/address";
import {GoogleMap, InfoWindowF, MarkerF} from "@react-google-maps/api";
import {
  ContactAddressDto,
  ContactAddressGetPickupLocationsApiArg,
  ContactAddressGetPickupLocationsApiResponse,
  useGetPickupLocationsMutation,
} from "../../../features/contactAddress/contactAddress-api";
import {CountryDto} from "../../../features/countries/countries-api";
import {
  ContactCategory,
  DeliveryAddressFormFields,
  PickupLocationComponentProps,
} from "./interfaces";
import {
  CitiesGetApiArg,
  CityDto,
  useGetCitiesMutation,
} from "../../../features/cities/cities-api";
import {FetchBaseQueryError} from "@reduxjs/toolkit/dist/query";
import {SerializedError} from "@reduxjs/toolkit";
import FeedbackIcon from "@mui/icons-material/Feedback";
import {getLangCodeFromI18n} from "../../../app/app.component";
import MuiPhoneNumber from "material-ui-phone-number";
import {getDeliveryAddressValidationSchema} from "../../pages/validation";
import {SelectedTabEnum} from "./deliveryAddressForm";
import {
  CountryFilter,
  useGetCountries,
} from "../../../common/hooks/useGetCountries";
import {useFormRequiredFields} from "../../../common/hooks/useFormRequiredFields";
import {useMapsLibrary} from "@vis.gl/react-google-maps";
import {$enum} from "ts-enum-util";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {customValueIsTrue} from "../../../../utils/helper.utils";

type PickupLocation = {
  id: number;
  countryCode: string;
  countryName: string;
  address: string;
  cityName: string;
  coordinates: {lat: number; lng: number};
  type: string;
  name: string | null;
  additional: string | null;
  customValues?:
    | {
        [key: string]: string;
      }
    | null
    | undefined;
};

const PHONE_NUMBER_REGEX = /^\+?\d*$/;

export default function PickupLocationComponent({
  formikProps,
  initialActiveCountry,
  initialActiveMarkerId,
  setValidationSchema,
  selectedTab,
  phoneNumberDefaultCountryCode,
  requiredFields = [],
  countryFilter,
}: PickupLocationComponentProps) {
  const langCode = getLangCodeFromI18n();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const {t} = useTranslation();
  const [pickupLocations, setPickupLocations] = useState<PickupLocation[]>([]);
  const [visiblePickupLocations, setVisiblePickupLocations] = useState<
    PickupLocation[]
  >([]);

  const mapsLib = useMapsLibrary("maps");
  const isLoaded = useMemo(() => !!mapsLib, [mapsLib]);

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [activeCountry, setActiveCountry] =
    useState<string | null>(initialActiveCountry);
  const [activeCity, setActiveCity] =
    useState<CityDto | null | undefined>(null);

  const [postOffices, setPostOffices] = useState<boolean>(true);
  const [parcelLockers, setParcelLockers] = useState<boolean>(true);
  const [activeMarkerId, setActiveMarkerId] = useState<number | null>(
    initialActiveMarkerId,
  );
  const [pickupLocationsData, setPickupLocationsData] =
    useState<ContactAddressGetPickupLocationsApiResponse | undefined>(
      undefined,
    );

  const [error, setError] =
    useState<FetchBaseQueryError | SerializedError | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [cities, setCities] = useState<CityDto[] | null>(null);
  const [zoom, setZoom] = useState<number>(2);
  const [citySearch, setCitySearch] = useState<string>("");
  const [phoneCountryCode, setPhoneCountryCode] =
    useState<string | undefined>("US");
  const context = formikProps as FormikProps<DeliveryAddressFormFields>;
  const [phoneInputValue, setPhoneInputValue] = useState<string | null>(null);
  const [locationCountryName, setLocationCountryName] =
    useState<string | null>(null);
  const [locationAddressLine, setLocationAddressLine] =
    useState<string | null>(null);

  const {renderRequiredMark} = useFormRequiredFields(requiredFields);

  const {data: countries} = useGetCountries({filter: countryFilter});

  const {data: phoneNumberCountries, isLoading: isPhoneNumberCountriesLoading} =
    useGetCountries({
      filter: CountryFilter.PhoneNumber,
    });

  const phoneNumberCountryCodes = useMemo(() => {
    return phoneNumberCountries?.map(
      (country) => country?.countryCode?.toLowerCase() ?? "",
    );
  }, [phoneNumberCountries]);

  const [getCities] = useGetCitiesMutation();
  const [getPickupLocations] = useGetPickupLocationsMutation();

  const loadCities = useCallback(() => {
    if (activeCountry) {
      const citiesGetApiArgs: CitiesGetApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        values: {
          countryCode: activeCountry,
          search: citySearch,
          limit: !!citySearch && citySearch.trim() !== "" ? 0 : 100,
        },
      };
      setIsLoading(true);
      getCities(citiesGetApiArgs)
        .unwrap()
        .then((result) => {
          setCities(result as CityDto[]);
          setError(undefined);
          setIsError(false);
          setIsSuccess(true);
        })
        .catch((result) => {
          setPickupLocationsData(undefined);
          setError(result);
          setIsError(true);
          setIsSuccess(false);
        })
        .finally(() => setIsLoading(false));
    }
  }, [activeCountry, citySearch]);

  const showCompanyName = useMemo(() => {
    return (
      formikProps?.values?.pickupLocationContactCategory ===
      ContactCategory.Business
    );
  }, [formikProps?.values?.pickupLocationContactCategory]);

  const addressConfig = useSelector(
    (state: RootState) => state.organizationConfigState?.modules?.address,
  );

  const showContactCategorySelector = useMemo(() => {
    return customValueIsTrue(addressConfig?.showContactCategorySelector);
  }, [addressConfig, customValueIsTrue]);

  useEffect(() => {
    loadCities();
  }, [activeCountry, citySearch]);

  useEffect(() => {
    if (selectedTab === SelectedTabEnum.right) {
      formikProps.setFieldValue(
        "pickupLocationPhoneNumber",
        phoneInputValue ?? "",
      );
      formikProps.setFieldValue(
        "pickupLocationContactAddressId",
        activeMarkerId ?? "",
      );
      formikProps.setFieldValue(
        "pickupLocationAddressLine",
        locationAddressLine ?? "",
      );
      formikProps.setFieldValue(
        "pickupLocationCountryCode",
        activeCountry ?? "",
      );
      formikProps.setFieldValue(
        "pickupLocationCityId",
        activeCity?.cityId ?? "",
      );
      formikProps.setFieldValue(
        "pickupLocationCountryName",
        locationCountryName ?? "",
      );
      formikProps.setFieldValue("pickupLocationPostOffices", postOffices ?? "");
      formikProps.setFieldValue(
        "pickupLocationParcelLockers",
        parcelLockers ?? "",
      );
    }
  }, [selectedTab]);

  useEffect(() => {
    if (activeCity) {
      const contactAddressGetPickupLocationsApiArgs: ContactAddressGetPickupLocationsApiArg =
        {
          organizationId: process.env
            .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
          values: {
            cityName: activeCity.cityName!,
            stateCode: activeCity.stateCode!,
            countryCode: activeCity.countryCode!,
          },
        };
      setIsLoading(true);
      getPickupLocations(contactAddressGetPickupLocationsApiArgs)
        .unwrap()
        .then((result) => {
          setPickupLocationsData(result as ContactAddressDto[]);
          setError(undefined);
          setIsError(false);
          setIsSuccess(true);
        })
        .catch((result) => {
          setPickupLocationsData(undefined);
          setError(result);
          setIsError(true);
          setIsSuccess(false);
        })
        .finally(() => setIsLoading(false));
    }
  }, [activeCity]);

  useEffect(() => {
    if (map) {
      fitBounds(map, pickupLocations);
    }
  }, [map, pickupLocations]);

  useEffect(() => {
    if (map) {
      const activeCountryDto = countries?.find(
        (country) => country.countryCode === activeCountry,
      );
      if (activeCountryDto) {
        fitCountryCenter(map, activeCountryDto);
      }
    }
  }, [map, activeCountry]);

  useEffect(() => {
    setVisiblePickupLocations(pickupLocations);
  }, [pickupLocations]);

  useEffect(() => {
    if (activeCity) {
      if (isSuccess) {
        if (pickupLocationsData) {
          parsePickupLocations(pickupLocationsData as ContactAddressDto[]).then(
            (result) => {
              setPickupLocations(result as PickupLocation[]);
            },
          );
        }
      }
      if (isError) {
        `${t("toasts.error")}. ${
          error && "status" in error ? (error.data as string) : ""
        }`;
      }
    } else {
      setPickupLocations([]);
    }
  }, [isLoading, activeCity, pickupLocationsData]);

  useEffect(() => {
    if (setValidationSchema && typeof setValidationSchema === "function") {
      setValidationSchema(
        getDeliveryAddressValidationSchema(
          "pickupLocation",
          t,
          "NonUS",
          phoneCountryCode,
          formikProps?.values?.pickupLocationContactCategory,
        ),
      );
    }
  }, [
    setValidationSchema,
    phoneCountryCode,
    formikProps?.values?.pickupLocationContactCategory,
  ]);

  const handlePhoneNumberChange = (
    event: any,
    formikProps: FormikProps<DeliveryAddressFormFields>,
  ) => {
    const newValue = event;
    if (newValue && !PHONE_NUMBER_REGEX.test(newValue)) return;
    formikProps.setFieldValue("pickupLocationPhoneNumber", newValue);
    setPhoneInputValue(newValue);
  };

  const handleOnLoad = (loadedMap: google.maps.Map) => {
    loadedMap.setCenter(new google.maps.LatLng(0, 0));
    setMap(loadedMap);
  };

  const handleOnUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const handleActiveMarker = (addressId: number) => {
    if (addressId !== activeMarkerId) {
      setActiveMarkerId(addressId);
    }
  };

  const handlePickupLocationMapSelect = async (
    pickupLocation: PickupLocation,
    formikProps: FormikProps<DeliveryAddressFormFields>,
  ) => {
    setActiveMarkerId(null);
    await formikProps.setFieldValue(
      "pickupLocationContactAddressId",
      pickupLocation.id,
    );
    formikProps.setFieldTouched("pickupLocationContactAddressId", true);
    formikProps.setFieldValue(
      "pickupLocationAddressLine",
      pickupLocation.address,
    );
    setActiveMarkerId(pickupLocation.id);
    setLocationAddressLine(pickupLocation.address);
  };

  const handleCountryChange = (
    event: ChangeEvent<HTMLInputElement>,
    formikProps: FormikProps<DeliveryAddressFormFields>,
  ) => {
    formikProps.setFieldValue("pickupLocationContactAddressId", "");
    formikProps.setFieldValue("pickupLocationAddressLine", "");
    formikProps.setFieldValue("pickupLocationCityId", "");
    const newValueCountryCode = event.target.value;

    const selectedCountry = countries?.find(
      (country) => country.countryCode === newValueCountryCode,
    );
    formikProps.setFieldValue("pickupLocationCountryCode", newValueCountryCode);
    formikProps.setFieldValue(
      "countryCustomValues",
      selectedCountry?.customValues,
    );
    formikProps.setFieldValue("country", selectedCountry?.name);
    setActiveCountry(newValueCountryCode);
    setActiveCity(null);
    setActiveMarkerId(null);
    setLocationAddressLine(null);
  };

  const handleCityChange = (
    city: CityDto,
    formikProps: FormikProps<DeliveryAddressFormFields>,
  ) => {
    if (city) {
      setZoom(6);
      formikProps.setFieldValue("pickupLocationContactAddressId", "");
      formikProps.setFieldValue("pickupLocationAddressLine", "");
      formikProps.setFieldValue("pickupLocationCityId", city.cityId);
      setActiveCity(city);
      setLocationAddressLine(null);
      setCitySearch("");
    } else {
      setActiveCity(null);
    }
    setActiveMarkerId(null);
  };

  const handlePickupLocationChange = (
    value: PickupLocation | null,
    formikProps: FormikProps<DeliveryAddressFormFields>,
  ) => {
    formikProps.setFieldValue(
      "pickupLocationContactAddressId",
      value?.id ?? "",
    );
    formikProps.setFieldValue(
      "pickupLocationAddressLine",
      value?.address ?? "",
    );
    formikProps.setFieldValue(
      "pickupLocationCountryName",
      value?.countryName ?? "",
    );
    setActiveMarkerId(value?.id ?? null);
    setLocationAddressLine(value?.address ?? null);
    setLocationCountryName(value?.countryName ?? null);
  };

  const handlePostOfficesChange = (
    event: ChangeEvent<HTMLInputElement>,
    formikProps: FormikProps<DeliveryAddressFormFields>,
  ) => {
    const newValue = event.target.checked;
    formikProps.setFieldValue("pickupLocationPostOffices", newValue);
    setPostOffices(newValue);
  };

  const handleParcelLockersChange = (
    event: ChangeEvent<HTMLInputElement>,
    formikProps: FormikProps<DeliveryAddressFormFields>,
  ) => {
    const newValue = event.target.checked;
    formikProps.setFieldValue("pickupLocationParcelLockers", newValue);
    setParcelLockers(newValue);
  };

  const getAddressLines = async (addressDto: ContactAddressDto) => {
    let localeString = "en-us";
    switch (i18next.language) {
      case "en":
        localeString = "en-us";
        break;
      case "ru":
        localeString = "ru-ru";
        break;
      case "ua":
        localeString = "uk-ua";
        break;
      default:
        localeString = "en-us";
        break;
    }

    const addressFormatter = new AddressFormatter(localeString);

    const addressLine1 =
      addressDto.customValues &&
      `address_${langCode}` in addressDto.customValues
        ? addressDto.customValues[`address_${langCode}`]
        : addressDto.addressLine ?? "";

    const result = addressFormatter.format({
      address1: addressLine1,
      address2: addressDto.addressLine2 ?? "",
      country: addressDto.countryCode ?? "",
      province: "",
      city: "",
      zip: addressDto.postalCode ?? "",
    });

    return result;
  };

  const parsePickupLocations = async (data: ContactAddressDto[]) => {
    const newPickupLocations = data.map(async (dto) => {
      const type =
        dto.customValues && dto.customValues["pickup_location_type"]
          ? dto.customValues["pickup_location_type"]
          : null;
      const addressLines = await getAddressLines(dto);
      let resultAddress = addressLines.filter((line) => line).join(", ");
      const countryStartIndex = resultAddress.lastIndexOf(", ");
      if (countryStartIndex > 0) {
        resultAddress = resultAddress.substring(0, countryStartIndex);
      }
      return {
        id: dto.contactAddressId ?? null,
        countryCode: dto.countryCode ?? null,
        countryName: dto.countryName ?? null,
        cityName: dto.cityName ?? null,
        address: resultAddress,
        coordinates: {
          lat: dto.latitude ?? null,
          lng: dto.longitude ?? null,
        },
        type: type,
        customValues: dto.customValues,
      };
    });

    return await Promise.all(newPickupLocations);
  };

  const fitBounds = (map: google.maps.Map, locations: PickupLocation[]) => {
    if (isLoaded) {
      const bounds = new google.maps.LatLngBounds();
      if (map) {
        if (locations.length === 0) {
          const activeCountryDto = countries?.find(
            (country) => country.countryCode === activeCountry,
          );
          if (activeCountryDto) {
            fitCountryCenter(map, activeCountryDto);
          }
        } else {
          locations.forEach((location: PickupLocation) => {
            bounds.extend(location.coordinates);
          });
          map.setCenter(locations[0].coordinates);
          setZoom(14);
        }
      }
    }
  };

  const fitCountryCenter = (map: google.maps.Map, country: CountryDto) => {
    if (isLoaded && map) {
      if (
        country &&
        country.customValues &&
        country.customValues["country_latitude"] &&
        country.customValues["country_longitude"] &&
        country.customValues["country_google_map_zoom"]
      ) {
        map.setCenter(
          new google.maps.LatLng(
            Number(country.customValues["country_latitude"]),
            Number(country.customValues["country_longitude"]),
          ),
        );
        setZoom(Number(country.customValues["country_google_map_zoom"]));
      }
    }
  };

  const getCityNameWithoutRegion = (cityName: string): string => {
    const indexOfRegionStart = cityName.indexOf("(");
    if (indexOfRegionStart !== -1) {
      return cityName.slice(0, indexOfRegionStart - 1);
    }
    return cityName;
  };

  const mapElement = useMemo(() => {
    return (
      <GoogleMap
        zoom={zoom}
        mapContainerStyle={{height: "600px", width: "100%"}}
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          zoomControlOptions: {
            position: window.google?.maps?.ControlPosition.LEFT_BOTTOM,
          },
        }}
        onLoad={handleOnLoad}
        onUnmount={handleOnUnmount}
        onClick={() => setActiveMarkerId(null)}
      >
        {pickupLocations.map(
          (pickupLocation: PickupLocation) =>
            pickupLocation.coordinates.lat !== 0 &&
            pickupLocation.coordinates.lng !== 0 && (
              <MarkerF
                key={pickupLocation.id}
                position={pickupLocation.coordinates}
                visible={
                  pickupLocation.type === "Parcel depot" ||
                  pickupLocation.type === "Cargo depot"
                    ? postOffices
                    : pickupLocation.type === "Poshtomat" ||
                      pickupLocation.type === "House Poshtomat"
                    ? parcelLockers
                    : false
                }
                onClick={() => handleActiveMarker(pickupLocation.id)}
              >
                {activeMarkerId === pickupLocation.id ? (
                  <Grid>
                    <InfoWindowF
                      position={pickupLocation.coordinates}
                      onCloseClick={() => setActiveMarkerId(null)}
                      options={{}}
                    >
                      <Grid container direction="column" rowGap="8px">
                        <Typography variant="body4">
                          {pickupLocation.name}
                        </Typography>
                        <Typography variant="body1">
                          {pickupLocation.address}
                        </Typography>
                        <Typography variant="body1" color="#4B4B4A">
                          {pickupLocation.additional}
                        </Typography>
                        <Button
                          variant="contained"
                          disableRipple
                          sx={{
                            textTransform: "none",
                            m: 0,
                            p: 0,
                            alignItems: "center",
                            height: "100%",
                          }}
                          onClick={() =>
                            handlePickupLocationMapSelect(
                              pickupLocation,
                              formikProps as FormikProps<DeliveryAddressFormFields>,
                            )
                          }
                          data-testid="select-address"
                        >
                          <Trans i18nKey={"select"}>
                            <Typography variant="body1">Select</Typography>
                          </Trans>
                        </Button>
                      </Grid>
                    </InfoWindowF>
                  </Grid>
                ) : null}
              </MarkerF>
            ),
        )}
      </GoogleMap>
    );
  }, [
    pickupLocations,
    zoom,
    postOffices,
    parcelLockers,
    activeMarkerId,
    formikProps,
  ]);

  const cityInput = useMemo(() => {
    return (
      <Grid>
        <Grid container columns={6} spacing={2}>
          <Grid xs={6} md={6} lg={6}>
            <Autocomplete
              filterOptions={(
                options: CityDto[],
                state: FilterOptionsState<CityDto>,
              ) => {
                return options.filter((option) => {
                  const search = state.inputValue?.toLowerCase();
                  return (
                    option.cityName.toLowerCase().includes(search) ||
                    (option.customValues &&
                      (option.customValues["city_en"]
                        ?.toLowerCase()
                        .includes(search) ||
                        option.customValues["city_ru"]
                          ?.toLowerCase()
                          .includes(search) ||
                        option.customValues["city_ua"]
                          ?.toLowerCase()
                          .includes(search)))
                  );
                });
              }}
              options={cities ?? []}
              getOptionLabel={(option: any) => {
                const city =
                  option.customValues &&
                  `city_${langCode}` in option.customValues
                    ? option.customValues[`city_${langCode}`]
                    : option.cityName;
                const state =
                  option.state?.customValues &&
                  `state_${langCode}` in option.state.customValues
                    ? option.state.customValues[`state_${langCode}`]
                    : option.stateName;
                return `${city} (${state})`;
              }}
              isOptionEqualToValue={(option, value) =>
                option?.cityId != value?.cityId
              }
              forcePopupIcon={true}
              onChange={(_: any, event: any) =>
                handleCityChange(
                  event,
                  formikProps as FormikProps<DeliveryAddressFormFields>,
                )
              }
              onInputChange={(_: any, value: string) => setCitySearch(value)}
              onBlur={() => setCitySearch("")}
              value={activeCity ?? null}
              renderInput={(params: any) => (
                <Field
                  {...params}
                  component={TextField}
                  fullWidth
                  variant="outlined"
                  size={isDesktop ? "normal" : "small"}
                  label={t("cityLabel")}
                  placeholder={
                    t("typeToSearchMore") +
                    renderRequiredMark("pickupLocationCityId")
                  }
                  data-testid="input-city"
                  name="pickupLocationCityId"
                  disabled={!activeCountry}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }, [
    cities,
    isDesktop,
    activeCountry,
    formikProps.values,
    isLoading,
    citySearch,
    langCode,
  ]);

  const pickupLocationsInput = useMemo(() => {
    return (
      <Grid>
        <Grid>
          <Grid container columns={6} spacing={2}>
            <Grid xs={6} md={6} lg={6}>
              <Autocomplete
                filterOptions={(
                  options: PickupLocation[],
                  state: FilterOptionsState<PickupLocation>,
                ) => {
                  return options.filter((option) => {
                    const search = state.inputValue?.toLowerCase();
                    return (
                      option.address.toLowerCase().includes(search) ||
                      (option.customValues &&
                        (option.customValues["address_en"]
                          ?.toLowerCase()
                          .includes(search) ||
                          option.customValues["address_ru"]
                            ?.toLowerCase()
                            .includes(search) ||
                          option.customValues["address_ua"]
                            ?.toLowerCase()
                            .includes(search)))
                    );
                  });
                }}
                options={visiblePickupLocations.filter((location) => {
                  if (activeCity) {
                    if (
                      getCityNameWithoutRegion(location.cityName)
                        ?.toLowerCase()
                        .includes(
                          getCityNameWithoutRegion(
                            activeCity.cityName,
                          ).toLowerCase(),
                        ) &&
                      (((location.type === "Poshtomat" ||
                        location.type === "House Poshtomat") &&
                        parcelLockers) ||
                        ((location.type === "Parcel depot" ||
                          location.type === "Cargo depot") &&
                          postOffices))
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                })}
                getOptionLabel={(option: PickupLocation) => option.address}
                disabled={!activeCountry}
                value={
                  visiblePickupLocations?.find(
                    (pickupLocation) =>
                      pickupLocation.id ==
                      formikProps?.values?.pickupLocationContactAddressId,
                  ) ?? null
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <Field
                    {...params}
                    name="pickupLocationContactAddressId"
                    component={TextField}
                    variant="outlined"
                    size={isDesktop ? "medium" : "small"}
                    fullWidth
                    label={
                      t("delivery.address.pickupLocation") +
                      renderRequiredMark("pickupLocationContactAddressId")
                    }
                    data-testid="input-pickupLocation"
                  />
                )}
                onChange={(_: any, value: PickupLocation | null) => {
                  handlePickupLocationChange(value, formikProps);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [
    visiblePickupLocations,
    activeCity,
    parcelLockers,
    postOffices,
    isDesktop,
    formikProps.values,
  ]);

  return (
    <Grid container direction={"column"} rowGap={4} padding={2}>
      <Grid>
        <Grid
          container
          columns={6}
          rowSpacing={{md: 4, xs: 3}}
          columnSpacing={2}
        >
          {showContactCategorySelector && (
            <Grid xs={12} md={12} lg={12}>
              <Field
                data-testid="radio-buttons-group-contact-category"
                component={RadioGroup}
                name="pickupLocationContactCategory"
                color="primary"
                aria-labelledby="radio-buttons-group-label"
                row={isDesktop}
              >
                {$enum(ContactCategory).map(
                  (value, key, wrappedEnum, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        control={
                          <Radio
                            color="primary"
                            sx={{color: "#ABABAB"}}
                          ></Radio>
                        }
                        value={key}
                        sx={{marginRight: "20px"}}
                        label={
                          <Trans i18nKey={`contactCategory.${key}`}>
                            {key}
                          </Trans>
                        }
                        checked={
                          key ===
                          formikProps.values.pickupLocationContactCategory
                        }
                      />
                    );
                  },
                )}
              </Field>
            </Grid>
          )}
          {showCompanyName && (
            <Grid xs={12} md={12} lg={12}>
              <Field
                component={TextField}
                variant="outlined"
                fullWidth
                size={isDesktop ? "normal" : "small"}
                InputLabelProps={{shrink: true}}
                label={
                  t("companyNameLabel") +
                  renderRequiredMark("pickupLocationCompanyName")
                }
                name="pickupLocationCompanyName"
                autoComplete="organization"
                data-testid="input-company-name"
              />
            </Grid>
          )}
          <Grid xs={3} md={3} lg={3}>
            <Field
              name="pickupLocationFirstName"
              component={TextField}
              variant="outlined"
              size={isDesktop ? "normal" : "small"}
              fullWidth
              label={
                t("firstNameLabel") +
                renderRequiredMark("pickupLocationFirstName")
              }
              InputLabelProps={{shrink: true}}
              autoComplete="given-name"
              data-testid="input-first-name"
            />
          </Grid>
          <Grid xs={3} md={3} lg={3}>
            <Field
              name="pickupLocationLastName"
              component={TextField}
              variant="outlined"
              size={isDesktop ? "normal" : "small"}
              fullWidth
              label={
                t("lastNameLabel") +
                renderRequiredMark("pickupLocationLastName")
              }
              InputLabelProps={{shrink: true}}
              autoComplete="family-name"
              data-testid="input-last-name"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid>
        <Grid container columns={6} spacing={2}>
          <Grid xs={6} md={3} lg={3}>
            {isPhoneNumberCountriesLoading ? (
              <CircularProgress size={30} />
            ) : (
              <>
                <MuiPhoneNumber
                  data-testid={"input-phone-number"}
                  sx={{"& svg": {height: "0.8em"}}}
                  disableAreaCodes={true}
                  fullWidth
                  size={isDesktop ? "medium" : "small"}
                  id="pickupLocationPhoneNumber"
                  label={
                    t("phoneLabel") +
                    renderRequiredMark("pickupLocationPhoneNumber")
                  }
                  name="pickupLocationPhoneNumber"
                  autoComplete="phoneNumber"
                  variant="outlined"
                  defaultCountry={phoneNumberDefaultCountryCode ?? "us"}
                  excludeCountries={["ru"]}
                  value={context.values.pickupLocationPhoneNumber}
                  countryCodeEditable={true}
                  onlyCountries={phoneNumberCountryCodes}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  onChange={(e: any, countryData: any) => {
                    setPhoneCountryCode(
                      countryData?.countryCode?.toUpperCase(),
                    );
                    handlePhoneNumberChange(
                      e
                        .replace(/\(/g, "")
                        .replace(/\)/g, "")
                        .replace(/-/g, "")
                        .replace(/ /g, ""),
                      context,
                    );
                  }}
                  error={Boolean(context.errors.pickupLocationPhoneNumber)}
                />
                <FormHelperText sx={{color: "#d32f2f", mt: 1, ml: 2}}>
                  {context.errors.pickupLocationPhoneNumber
                    ? context.errors.pickupLocationPhoneNumber
                    : ""}
                </FormHelperText>
              </>
            )}
          </Grid>
          <Grid xs={6} md={3} lg={3}>
            <Field
              component={TextField}
              variant="outlined"
              fullWidth
              size={isDesktop ? "normal" : "small"}
              InputLabelProps={{shrink: true}}
              label={
                t("emailLabel") + renderRequiredMark("pickupLocationEmail")
              }
              name="pickupLocationEmail"
              autoComplete="email"
              data-testid="input-pickup-email"
              disabled={false}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid>
        <Grid container columns={6} spacing={2}>
          <Grid xs={6} md={6} lg={6}>
            <Field
              name="pickupLocationCountryCode"
              component={TextField}
              select
              variant="outlined"
              size={isDesktop ? "normal" : "small"}
              fullWidth
              label={
                t("countryLabel") +
                renderRequiredMark("pickupLocationCountryCode")
              }
              InputLabelProps={{shrink: true}}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleCountryChange(
                  event,
                  formikProps as FormikProps<DeliveryAddressFormFields>,
                )
              }
              data-testid="select-country"
            >
              {countries?.map((country) => (
                <MenuItem
                  key={country.countryCode ?? ""}
                  value={country.countryCode ?? ""}
                  data-testid={`option-${country.countryCode}`}
                >
                  {country.customValues &&
                  `country_translation_${langCode}` in country.customValues
                    ? country.customValues[`country_translation_${langCode}`]
                    : country.name}
                </MenuItem>
              ))}
            </Field>
          </Grid>
        </Grid>
      </Grid>

      {cityInput}

      {pickupLocationsInput}

      <Grid>
        <Grid container spacing={2}>
          <Grid>
            <Field
              name="pickupLocationPostOffices"
              component={CheckboxWithLabel}
              type="checkbox"
              Label={{
                label:
                  t("postOffices") +
                  renderRequiredMark("pickupLocationPostOffices"),
              }}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handlePostOfficesChange(
                  event,
                  formikProps as FormikProps<DeliveryAddressFormFields>,
                )
              }
              data-testid="checkbox-postOffices"
            />
          </Grid>
          <Grid mr={1}>
            <Field
              name="pickupLocationParcelLockers"
              component={CheckboxWithLabel}
              type="checkbox"
              Label={{
                label:
                  t("parcelLockers") +
                  renderRequiredMark("pickupLocationParcelLockers"),
              }}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleParcelLockersChange(
                  event,
                  formikProps as FormikProps<DeliveryAddressFormFields>,
                )
              }
              data-testid="checkbox-parcelLockers"
            />
          </Grid>
          {(isLoading ||
            (pickupLocations.length === 0 &&
              activeCity &&
              !isLoading &&
              map)) && (
            <Grid
              container
              alignItems={"center"}
              justiy-content={"center"}
              ml={1}
            >
              {isLoading ? (
                <CircularProgress style={{height: "30px", width: "30px"}} />
              ) : (
                <>
                  <FeedbackIcon color="primary" style={{marginRight: "8px"}} />
                  <Trans i18nKey={"delivery.address.noPickupLocations"}>
                    <Typography variant="body1">
                      Sorry, but there are no available pickup locations
                    </Typography>
                  </Trans>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid>
        {isLoaded ? (
          mapElement
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress style={{height: "150px", width: "150px"}} />
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
