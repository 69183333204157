import * as yup from "yup";
import {CountryCode, validatePhoneNumberLength} from "libphonenumber-js";
import {ContactCategory} from "../DeliveryAddress/components/interfaces";

const checkNumberLenght = (value: string, code: string | undefined) => {
  const result = validatePhoneNumberLength(value, code as CountryCode);
  return result;
};

export const getDeliveryAddressValidationSchema = (
  form: string,
  t: any,
  countryType?: string | null,
  countryCode?: string | null | undefined,
  contactCategory?: ContactCategory | null,
) => {
  const isUSMode = countryType === "US";
  const isBusiness = contactCategory
    ? contactCategory === ContactCategory.Business
    : false;

  const schema =
    form === "toAddress"
      ? yup.object().shape({
          toAddressFirstName: yup
            .string()
            .trim()
            .required(t("validation.deliveryAddress.firstName"))
            .max(50, t("validation.deliveryAddress.firstNameLength")),
          toAddressLastName: yup
            .string()
            .trim()
            .required(t("validation.deliveryAddress.lastName"))
            .max(50, t("validation.deliveryAddress.lastNameLength")),
          toAddressPhoneNumber: yup
            .string()
            .required(t("validation.phoneVerification.requiredNumber"))
            .test(
              "is-short",
              t("validation.phoneVerification.shortNumber"),
              (value: string | undefined): any => {
                if (value) {
                  return (
                    checkNumberLenght(value, countryCode || "US") !==
                    "TOO_SHORT"
                  );
                }
              },
            )
            .test(
              "is-long",
              t("validation.phoneVerification.longNumber"),
              (value: string | undefined): any => {
                if (value) {
                  return (
                    checkNumberLenght(value, countryCode || "US") !== "TOO_LONG"
                  );
                }
              },
            ),
          toAddressEmail: yup
            .string()
            .email(t("validation.common.emailFormat"))
            .max(256, t("validation.common.emailLengthMax"))
            .required(t("validation.common.emailRequired")),
          toAddressCountryCode: yup
            .string()
            .required(t("validation.address.countryRequired")),
          toAddressRegionCode: yup
            .string()
            .required(t("validation.address.regionRequired"))
            .max(100, t("validation.address.regionLength")),
          toAddressDistrict: !isUSMode
            ? yup
                .string()
                .trim()
                .max(60, t("validation.address.districtLength"))
            : yup.string(),
          toAddressCity: yup
            .string()
            .trim()
            .required(t("validation.address.cityRequired"))
            .max(60, t("validation.address.cityLength")),
          toAddressPostalCode: yup
            .string()
            .trim()
            .required(t("validation.address.postalCodeRequired"))
            .min(4, t("validation.address.postalCodeLengthMin"))
            .max(10, t("validation.address.postalCodeLength")),
          toAddressStreetName: !isUSMode
            ? yup
                .string()
                .trim()
                .required(t("validation.address.streetNameRequired"))
                .max(60, t("validation.address.streetNameLength"))
            : yup
                .string()
                .trim()
                .required(t("validation.address.addressLineRequired"))
                .max(120, t("validation.address.addressLineLength")),
          toAddressHouseNumber: !isUSMode
            ? yup
                .string()
                .trim()
                .required(t("validation.address.houseNumberRequired"))
                .max(10, t("validation.address.houseNumberLength"))
            : yup.string(),
          toAddressApartment: !isUSMode
            ? yup
                .string()
                .trim()
                .max(10, t("validation.address.apartmentLength"))
            : yup
                .string()
                .trim()
                .max(120, t("validation.address.addressLine2Length")),
          toAddressCompanyName: isBusiness
            ? yup
                .string()
                .trim()
                .required(t("validation.deliveryAddress.companyNameRequired"))
                .max(100, t("validation.deliveryAddress.companyNameLength"))
            : yup.string(),
          toAddressContactCategory: yup.string().nullable(),
        })
      : form === "pickupLocation"
      ? yup.object().shape({
          pickupLocationFirstName: yup
            .string()
            .trim()
            .required(t("validation.deliveryAddress.firstName"))
            .max(50, t("validation.deliveryAddress.firstNameLength")),
          pickupLocationLastName: yup
            .string()
            .trim()
            .required(t("validation.deliveryAddress.lastName"))
            .max(50, t("validation.deliveryAddress.lastNameLength")),
          pickupLocationPhoneNumber: yup
            .string()
            .required(t("validation.phoneVerification.requiredNumber"))
            .test(
              "is-short",
              t("validation.phoneVerification.shortNumber"),
              (value: string | undefined): any => {
                if (value) {
                  return (
                    checkNumberLenght(value, countryCode || "US") !==
                    "TOO_SHORT"
                  );
                }
              },
            )
            .test(
              "is-long",
              t("validation.phoneVerification.longNumber"),
              (value: string | undefined): any => {
                if (value) {
                  return (
                    checkNumberLenght(value, countryCode || "US") !== "TOO_LONG"
                  );
                }
              },
            ),
          pickupLocationCountryCode: yup
            .string()
            .required(t("validation.address.countryRequired")),
          pickupLocationContactAddressId: yup
            .string()
            .required(t("validation.deliveryAddress.pickupLocationRequired"))
            .nullable(),
          pickupLocationEmail: yup
            .string()
            .email(t("validation.common.emailFormat"))
            .max(256, t("validation.common.emailLengthMax"))
            .required(t("validation.common.emailRequired")),
          pickupLocationCompanyName: isBusiness
            ? yup
                .string()
                .trim()
                .required(t("validation.deliveryAddress.companyNameRequired"))
                .max(100, t("validation.deliveryAddress.companyNameLength"))
            : yup.string(),
          pickupLocationContactCategory: yup.string().nullable(),
        })
      : {};
  return schema;
};
