import React, {useCallback, useEffect, useMemo, useState} from "react";
import Uppy from "@uppy/core";
import Webcam from "@uppy/webcam";
import Grid from "@mui/material/Unstable_Grid2";
import {Dashboard} from "@uppy/react";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/webcam/dist/style.min.css";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../features/workflowTriggers/workflowExecution-api";
import {toastError} from "../utils/toastMessages";

import Russian from "@uppy/locales/lib/ru_RU";
import English from "@uppy/locales/lib/en_US";
import Ukrainian from "@uppy/locales/lib/uk_UA";
import i18next from "i18next";

type UseFileUploaderProps = {
  onUploadSuccess?: (result: UploadFileSuccessResult) => void;
  onCancel?: () => void;
  fileSizeLimit?: number;
  isHidden?: boolean;
};

export type UploadFileSuccessResult = {
  file: any;
  url: string;
};

export const useFileUploader = ({
  onUploadSuccess,
  onCancel,
  fileSizeLimit = 20 * 1024 * 1024, // 20MB
  isHidden = false,
}: UseFileUploaderProps) => {
  const getPresignedUrlWorkflowId = useSelector(
    (state: RootState) =>
      state?.organizationConfigState?.workflows?.getPresignedUrlWorkflowId,
  );
  const [runWorkflow] = useWorkflowExecutionMutation();
  const customerId = useSelector(
    (state: RootState) => state?.userState?.contactId,
  );
  const lang = i18next.language;
  const uppyLocale = useMemo(() => {
    switch (lang) {
      case "ru":
        return Russian;
      case "ua":
        return Ukrainian;
      default:
        return English;
    }
  }, [lang]);

  const createUppy = useCallback(() => {
    return new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["image/*"],
        maxFileSize: fileSizeLimit,
      },
      autoProceed: false,
      locale: uppyLocale,
    }).use(Webcam, {
      modes: ["picture"],
      facingMode: "environment",
    } as any);
  }, [fileSizeLimit, uppyLocale]);

  const [uppy] = useState(createUppy);

  // add upload handler
  useEffect(() => {
    const uploadHandler = async (fileIds: string[]) => {
      for (const fileId of fileIds) {
        const file = uppy?.getFile(fileId);

        if (!file) {
          console.error("No file found");
          return;
        }

        if (!file?.type) {
          console.error("No file type found");
          return;
        }

        if (!file?.name) {
          console.error("No file name found");
          return;
        }

        if (!file?.data) {
          console.error("No file data found");
          return;
        }
        console.log(file);

        uppy.setFileState(fileId, {
          progress: {
            ...uppy?.getFile(fileId)?.progress,
            uploadComplete: false,
            uploadStarted: true,
          },
        });

        const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
          organizationId: process.env
            .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
          workflowId: getPresignedUrlWorkflowId,
          values: {
            variables: {
              fileName: file.name,
              contentType: file.type,
              customerId,
            },
          },
        };

        const getPresignedUrlWorkflowResult: any = await runWorkflow(
          executeWorkflowApiArgs,
        );

        const url = getPresignedUrlWorkflowResult?.data?.outputs?.presignedUrl;
        if (!url) {
          console.error("No presigned URL found");
          return;
        }

        const xhr = new XMLHttpRequest();
        xhr.open("PUT", url, true);
        xhr.setRequestHeader("Content-Type", file.type);

        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const percentComplete = (event.loaded / event.total) * 100;
            uppy?.setFileState(fileId, {
              progress: {
                ...uppy?.getFile(fileId)?.progress,
                bytesUploaded: event.loaded,
                bytesTotal: event.total,
                percentage: percentComplete,
              },
            });
          }
        };

        xhr.onload = () => {
          if (xhr.status === 200) {
            uppy?.setFileState(fileId, {
              progress: {
                ...uppy?.getFile(fileId)?.progress,
                uploadComplete: true,
                percentage: 100,
              },
            });
            uppy?.emit("upload-success", {
              file,
              url,
            } as UploadFileSuccessResult);
          } else {
            console.error(`Upload failed: ${xhr.statusText}`);
          }
        };

        xhr.onerror = () => {
          toastError("Error uploading file");
          console.error("Upload failed due to network error.");
        };

        xhr.send(file.data);
      }
    };

    uppy?.addUploader(uploadHandler);

    return () => uppy?.removeUploader(uploadHandler);
  }, [uppy, getPresignedUrlWorkflowId, customerId, runWorkflow]);

  // add cancel handler
  useEffect(() => {
    const onCancelHandler = () => {
      if (onCancel) onCancel();
    };

    uppy?.on("cancel-all", onCancelHandler as any);

    return () => {
      uppy?.off("cancel-all", onCancelHandler as any);
    };
  }, [uppy, onCancel]);

  // add upload success handler
  useEffect(() => {
    const onUploadSuccessHandler = (result: UploadFileSuccessResult) => {
      if (onUploadSuccess) onUploadSuccess(result);
    };

    uppy?.on("upload-success", onUploadSuccessHandler as any);

    return () => {
      uppy?.off("upload-success", onUploadSuccessHandler as any);
    };
  }, [uppy, onUploadSuccess]);

  const uploadFileComponent = useMemo(() => {
    if (isHidden) return null;

    return (
      <Grid>
        {uppy && (
          <Dashboard
            uppy={uppy}
            plugins={["Webcam"]}
            proudlyDisplayPoweredByUppy={false}
            height={400}
            width={"100%"}
            showProgressDetails={true}
            metaFields={[{id: "name", name: "Name", placeholder: "File name"}]}
          />
        )}
      </Grid>
    );
  }, [uppy, isHidden]);

  return {uploadFileComponent};
};
